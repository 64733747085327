.sample-search-container {
  width: $dropdown-width;
}

.sample-previews {
  @extend .row;
}

.sample-preview {
  @extend .medium-2;
  @extend .small-3;
  @extend .columns;
  .thumbnail {
    margin-bottom: 0.5rem;
  }
  > a.name-link {
    font-size: $small-font-size;
  }
  .close-button {
    top: auto;
    //bottom: 0;
    font-size: 1.25rem;
  }
  position: relative;
  margin-bottom: 1rem;
}

.products-results {
  //border: 1px solid red;
  //box-shadow: 0 0 0 1px rgba($black, 0.2);
  max-height: 200px;
  overflow: auto;

  background-color: white;
  border: $dropdown-border;
  display: block;
  padding: 0;
  margin-bottom: 1rem;

  z-index: 10;
  border-radius: $dropdown-radius;

  > a {
    display: block;
    padding-top: 7px;
    color: $black;
    font-weight: normal;
    text-decoration: none;
    &:hover,
    &:focus {
      background-color: _transparentize(get-color(primary), 0.8);
    }
    > img {
      float: left;
      margin-right: 10px;
      margin-top: -7px;
    }
    .hint {
      font-size: $small-font-size;
      color: get-color(secondary);
    }
  }
}
