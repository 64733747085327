.user-file-attachments
{

  .large-attachment
  {
    margin-bottom: rem-calc(20px);
  }

  .pdfobject-container
  {
    height: 600px;
  }

  .pdfobject {
     border: 1px solid #666;
  }

  .thumbnails
  {

    a
    {
      display: inline-block;
      margin: 0 5px 5px 0;

      img
      {
        display: block;
        width: 100px;
        height: 100px;
      }
    }
  }

}



@include breakpoint(small only) {
  .user-file-attachments
  {
    .pdfobject-container
    {
      height: 300px;
    }
  }
}
