@use 'sass:color';

a {
  font-weight: $global-weight-bold;
}

a.subdued {
  color: get-color(secondary);
  &:hover,
  &:focus {
    color: color.scale(get-color(secondary), $lightness: -14%);
  }
}

@mixin link_standard {
  color: $anchor-color;
  text-decoration: none;
  &:hover {
    color: $anchor-color-hover;
    text-decoration: $anchor-text-decoration-hover;
  }
}

a.chevron,
.chevron > a,
a .chevron > span {
  &:after {
    display: inline-block;
    content: '\00BB';
    margin-left: 0.25rem;
  }
}

button.back-chevron,
a.back-chevron,
.back-chevron > a,
a .back-chevron > span {
  &:before {
    display: inline-block;
    content: '\00AB';
    margin-right: 0.25rem;
  }
}

a.block
{
  display: block;
}

a.nowrap
{
  white-space: nowrap;
}
