.policy-document, .payments
{
  background: #fff;

  .top-bar-container-row {
    margin-bottom: rem-calc(40px);
  }

  .top-bar
  {
    padding: rem-calc(20px 6px);
  }

  @include breakpoint(small only) {
    .top-bar-container-row {
      margin-bottom: rem-calc(42px);

      .logo {
        margin-bottom: 0;
      }
    }
  }

  h3
  {
    font-weight: bold;
    font-size: 100%;
  }

  p
  {
    font-size: rem-calc(15px);
    line-height: 150%;
  }

  p.space-after
  {
    padding-bottom: rem-calc(40px);
  }


  span.Apple-tab-span {
    white-space:pre
  }

  table
  {
    tr td
    {
      vertical-align: top;
    }
  }

  .callout
  {
    border: 1px solid #999;
    h3
    {
      margin-bottom: rem-calc(15px);
    }
  }


  a:not([href]) {
    cursor: default;
    text-decoration: none;
    color: inherit;
    font-weight: normal;
  }
}

// terms and conditions list
.nested-list
{
  
  font-size: rem-calc(14px);

  ol { counter-reset: item; list-style-position: inside; margin-left: 1.8em;}

  li { display: block;margin: 0px;
    padding: 0px;
    text-indent: -1.8em;
  }
  li:before { content: counters(item, ".") " "; counter-increment: item }

  > ol > li
  {
    margin-top: rem-calc(15px);
    font-weight: bold;

    > ol, > span
    {
      font-weight: normal;
    }
  }
}

