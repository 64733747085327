.tabs {
  border: none;
  background: transparent;
}
.tabs-title {
  border: 1px solid $white;
  > a {
    font-size: 14px;
  }
  &:not(.is-active) {
    border-color: $light-gray;
    background-color: _transparentize($tab-background, 0.5);
    > a {
      font-weight: normal;
    }
  }
}
@include breakpoint(medium only) {
  .tabs-title {
    > a {
      padding: 1rem 0.5rem;
    }
  }
}
@include breakpoint(small only) {
  .tabs {
    @include tabs-container-vertical;
  }
}
