.careers-spec-summary
{
  .button.success
  {
    margin-top: rem-calc(15px);
  }
}

.careers
{

  .job-article
  {
    position: relative;
    .apply-top
    {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;

      @include breakpoint(medium down) {
        position: static;
        margin-bottom: rem-calc(20px);
      }
    }
  }

  .job-properties-list
  {
    list-style-type: none;
    margin-left: 0;

    @include clearfix;

    .property
    {
      float: left;
      min-width: rem-calc(135px);
      display: inline-block;
      font-weight: bold;
    }

    .value
    {
      display: block;
      margin-left: rem-calc(135px);
    }


  }
}
