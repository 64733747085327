@use 'sass:color';

body.cart
{

  label
  {
    font-size: rem-calc(14px);
  }
  .admin-panel
  {
    label, .button
    {
      display: inline-block;
      vertical-align: top;
      &:first-child
      {
        margin-right: rem-calc(15px);
      }
    }
  }
  .price-breakdown, .trade-savings
  {
    font-size: 85%;
    white-space: nowrap;
    color: _darken(get-color(secondary), 10%);
    max-width: 180px;

    .additional-labour
    {
      white-space: normal;
    }
  }

  .trade-savings, .trade-savings-total
  {
    color: _darken(get-color(success), 8%);
  }

  .trade-savings
  {
    margin-right: rem-calc(-30px);
  }

  .parts-summary, .size-summary, .lengths-notes
  {
    color: _darken(get-color(secondary), 20%);
  }

  .delivery-options .cart-embedded-postcode-module
  {
    background-color: $white;
    margin: 0 -10px 0 0;
  }

  // is ul
  .delivery-info-for-customer
  {
    &.info-panel {
      background-color: #fff;
    }
    color: #000;
    h3
    {
      font-size: rem-calc(18px);
      line-height: rem-calc(16px);
    }
    h4 {
      font-size: rem-calc(14px);
      font-weight: bold;
    }
    ul
    {
      font-size: rem-calc(14px);
      margin-left: 10px;
    }

  }

  .samples-list
  {
    font-size: 0;
    .sample
    {
      border-radius: $panel-small-radius;
      background: #ccc;
      display: inline-block;
      font-size: rem-calc(12px);
      margin: 5px 13px 0 0;
      padding: 1px 19px 1px 4px;
      position: relative;

      .close-button {
        position: absolute;
        top: -1px;
        right: 4px;
        font-size: 1.25rem;

        &:hover{
          color: get-color(alert);
        }
      }

    }
  }

.provisional-delivery-info
{
  margin-left: 0px;
  margin-right: -10px;
}

  .delivery-options .cart-embedded-postcode-module, .provisional-delivery-info
  {
    padding: 5px 10px;

    label
    {
      color: #000;
    }

    p.hint-text
    {
      opacity: 1;
      color: #666;
    }
    .current-address
    {
      font-size: rem-calc(13px);
      margin-left: rem-calc(10px);
      white-space: pre;
    }

    .edit-postcode-container
    {
      margin-top: rem-calc(8px);
      margin-bottom: 0;
      text-align: left;
      label
      {
        display: block;
      }

      .postcode-lookup-container
      {
        margin-bottom: rem-calc(15px);
      }

      .postcode-search-results
      {
        margin-top: rem-calc(5px);
      }
    }

    a
    {
      font-weight: normal;
    }
  }

}

.provisional-delivery-info.cart
{
  padding: 5px 10px;
  background-color: $primary-shade-2;
  margin-top: 8px;
  font-size: rem-calc(12px);
  color: $white;
  h4
  {
    font-size: rem-calc(15px);
    margin-bottom: 4px;
  }

  div
  {
    color: _darken(#fff, 30%);
    label
    {
      font-size: rem-calc(12px);
      line-height: rem-calc(12px);
      display: inline-block;
      color: _darken(#fff, 30%);
      min-width: rem-calc(170px);
    }

    span
    {
      font-weight: bold;
      color: $white;

      &.warning
      {
        color: get-color(warning);
      }
    }
  }
}


.cart-items {
  
  width: 100%;

  @include breakpoint(small down) {
    @include table-stack;

    td.totals
    {
      text-align: right;
    }
  }

  thead {
    background-color: transparent;
    tr th:first-child {
      padding-left: 0;
    }
  }

  thead, tbody, tfoot {
    border: none;
  }

  > tbody > tr.clear-row {
    background-color: $body-background;

    &.first-admin-actions-row {
      td.add-button-cell {
        padding: rem-calc(30px 0 10px 0);
      }
    }

    &.first-delivery-info-row {
      td {
        padding-top: 50px;
        padding-left: 0;
      }
      
    }
  }

  > tbody > tr > td {
    vertical-align: top;
    &.edit-quantity {
      > input {
        display: inline-block;
        //margin-top: -0.5em;
        margin-bottom: 0;
      }
    }
    > span.units {
      @extend .help-text;
      margin-left: 0.5rem;
    }
    input.adminSubtotal
    {
      margin-bottom: 0;
    }
  }

  .ppm-info {
    font-size: rem-calc(11.5px);
  }


  .item-edit-lengths-link, .ppm-info.admin
  {
    display: block;
    margin-top: rem-calc(10px);
    font-size: rem-calc(11.5px);

    a
    {
      white-space: nowrap;
    }

    &.warning a
    {
      color: get-color(warning);
    }
  }

  .adminSubtotal.adjusted
  {
    border: 1px solid get-color(primary);
    background-color: rgba(get-color(primary), 0.15);
  }


  input.danger
  {
    @include field_invalid;
  }

  input.warning
  {
    @include field_warning;
  }

  .simple-bespoke-item-images-container
  {
    input.simple-bespoke-file
    {
      font-size: rem-calc(13px);
      &:disabled
      {
        background: #fff;
        color: $disabled-color;
      }
    }
  }


}


.large-order-warning
{
  display: block;
  background: get-color(primary);
  text-align: center;
  font-size: rem-calc(14px);
  padding: rem-calc(4px 8px);
  color: #fff;

  &:hover
  {
    color: #fff
  }

  a, a:hover, a:active
  {
    color: get-color(warning);
  }
}

.delete-item,.delme {
  //position: relative;
  font-size: 0.7rem;
  > .close-button {
    z-index: auto;
    position: relative;
    right: auto;
    top: auto;
  }
}


.continue-container {
  padding-top: rem-calc(27px);
  .continue-button,
  .continue-warning {
    margin-top: rem-calc(15px);
    &.cart-edit-mode-button,
    &.checkout {
      margin-top: rem-calc(6px);
    }
  }
}

#cart_shipping.warning
{
  font-weight: bold;
  color: get-color(primary);
}

.delivery-options
{
  h4
  {
    font-size: 0.875rem;
    font-weight: bold;
  }

  .radio-options label
  {
    display: inline-block;
    &:first-child
    {
      margin-right: 2ex;
    }
  }

}
