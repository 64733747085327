@use 'sass:color';
@mixin sidebar-panel ($colour) {
  background-color: $colour;
  color: $white;
  @extend .alt-link-colours;
}

.sidebar-panel {
  padding: 1rem;
  background-color: $white;
  margin-bottom: 1rem;
  position: relative;
  
  p, .button, select {
    &:last-child {
      margin-bottom: 0;
    }
  }

  &.user-account
  {
    p .button
    {
      margin-bottom: 0;
    }

    @include breakpoint(medium only) {
      font-size: rem-calc(14px);
    }
  }

  .full-width {
    display: block;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  p.heading
  {
    text-transform: uppercase;
    margin-bottom: 13px;
  }

  input.text:disabled, textarea:disabled
  {
    @include read_only_panel;
  }

  .sidebar-toggle-button
  {
    position: absolute;
    top: 0;
    right: 0;
  }

  .customer-number {
    font-weight: bold;
    color: get-color(primary);
    position: relative;
    margin-top: -6px;
  }

  .trade-sector {
    color: $support-green
  }


  .trade-account-star, .staff-account-icon {
    margin-top: -10px;
    vertical-align: super;
    font-size: rem-calc(13px);
    font-weight: normal;

    i.fi-star, i.fi-torso{
      display: inline-block;
      margin-right: 3px;
      position: relative;
      top: -1px;
    }
  }

  span.trade-account, .trade-account-star {
    color: $support-green;
  }

  span.staff-account, .staff-account-icon {
    color: get-color(primary);
  }

  .contact-type
  {
    span
    {
      display: inline-block;
      min-width: rem-calc(100px);
    }
  }

}

.sidebar-panel1 {
  @include sidebar-panel($primary-shade-1);
}

.sidebar-panel2 {
  @include sidebar-panel(get-color(primary));
}

.sidebar-panel3 {
  @include sidebar-panel($primary-shade-2);
}

.sidebar-panel-green {
  @include sidebar-panel($support-green);
}

#checkbox-provisional-delivery-info
{
  overflow: hidden;
  .provisional-delivery-info.cart
  {
    margin: 0;
    padding: 0;
  }
}


.sidebar-panel4 {
  background-color: #fff;
  color: #000;

  p
  {
    color: #000;
  }

  @extend .alt-link-colours;



}

.sidebar-panel-cart {

  padding: 0;
  font-size: rem-calc(18);
  border: none;

  .delivery-options, .edit-postcode-container
  {
    text-align: center;
    margin-bottom: 1.5rem;

    label
    {
      display: inline-block;
      color: #fff;
      font-size: rem-calc(15);
      &:first-child
      {
        margin-right: 2ex;
      }
    }

    .delivery-address-actions
    {
      font-weight: normal;
      text-decoration: underline;

      &.danger
      {
        color: get-color(alert);
      }
    }

    .link-colour
    {
      color: get-color(alert);
    }
  }

  .delivery-loading
  {
    opacity: 0.55;
  }

  .checkout-button.success
  {
    -moz-transition: none;
    -o-transition: color 0 ease-in;
    -webkit-transition: none;
    text-decoration: none;
    transition: none;
  }

  .checkout-button.success.disabled, .checkout-button.success.disabled:hover
  {
    -moz-transition: none;
    -o-transition: color 0 ease-in;
    -webkit-transition: none;
    background-color: $disabled-color;
    color: #DDD;
    opacity: 1;
    transition: none;
  }

  .delivery-truck-icon {
    width: 60px;
    height: 34px;
    background: url("/images/delivery%20truck.png") no-repeat;
  }

  .free-delivery {
    padding: 1rem;
    p
    {
      margin-bottom: 0;

      span
      {
        display: block;
        font-size: rem-calc(13px);
      }
    }
  }

  .trade-savings-total
  {
    color: get-color(success);
  }



  table {
    width: 100%;
    font-size: rem-calc(14);
  }

  thead, tbody, tfoot {
    border: none;
    background-color: transparent;
  }

  input {
    margin-bottom: 0;
  }

  td.narrow
  {
    width: 10px; // forces column to be narrow, but it wil be pushed wider by content
    white-space: nowrap;
  }

  td.overriden
  {
    color: color.scale(get-color(primary), $lightness: 60%);
  }

  // still in .sidebar-panel-cart
  .cart-title {
    border: none;
    color: $white;
    display: block;
    font-size: rem-calc(16);
    font-weight: $global-weight-bold;
    padding: 1.25rem 1rem;
    position: relative;
    text-decoration: none;
    &:hover,
    &:focus {
      color: _transparentize($white, 0.3);
      background-color: transparent;
    }
    &::before {
      content: '+';
      position: absolute;
      right: 1rem;
      top: 50%;
      margin-top: -0.5rem;
    }

  }

  &.expanded > .cart-title::before {
    content: '–';
  }

  .cart-content {
    background-color: transparent;
    border: none;
    color: $white;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    max-height: 0;
    overflow: hidden;
    @include transition_sidebar_cart;

    &.edit-address-container
    {
      label
      {
        color: $white;
      }
      .current-address
      {
        font-size: rem-calc(13px);
        margin-left: rem-calc(10px);
        white-space: pre;
      }
    }

    .edit-postcode-container
    {
      margin-top: rem-calc(15px);
      margin-bottom: 1rem;
      text-align: left;
      label
      {
        display: block;
      }

      .postcode-lookup-container
      {
        margin-bottom: rem-calc(15px);
      }

      .postcode-search-results
      {
        margin-top: rem-calc(5px);
      }
    }

    .provisional-delivery-info
    {
      font-size: 12px;
      color: $white;
      margin-bottom: 1rem;
      h4
      {
        font-size: 15px;
        margin-bottom: 4px;
      }

      div
      {
        color: _darken(#fff, 30%);
        label
        {
          font-size: rem-calc(12px);
          line-height: rem-calc(12px);
          display: inline-block;
          color: _darken(#fff, 30%);
          min-width: rem-calc(170px);
        }

        span
        {
          font-weight: bold;
          color: $white;

          &.warning
          {
            color: get-color(warning);
          }
        }
      }
    }

    .checkout-button
    {
      margin-bottom: 1rem;
    }

  }



  &.expanded .cart-content
  {
    margin: 1rem;
    margin-top: 0;
    max-height: 650px;
    overflow-y: auto;


  }
  &.animating .cart-content
  {
    overflow-y: hidden;
  }

}

.sidebar-panel-catalogue {

  p
  {
    color: #000;
  }

  a.block
  {
    margin: 0 -16px 0 -16px;
  }

  .text-center
  {
    margin-top: 10px;

    p
    {
      margin-bottom: 14px;
    }
  }
}



.sidebar-panel.timber-info
{

  > div
  {
    position: relative;
    @include clearfix;
  }

  .all-link
  {
    position: absolute;
    top: 0;
    right: 0;
  }

  .timber-info-content
  {

    .timber-thumbnail
    {
      // display: block;
      // max-width: 50%;
    }

    p
    {
      font-size: rem-calc(14);
      margin: rem-calc(0 0 13px 0);

      &.timber-name
      {
        font-size: rem-calc(15px);
        margin: rem-calc(13px 0 0 0);
      }
    }

    .previous-link
    {
      float: left;
    }

    .next-link
    {
      float: right;
    }
  }


}



@include breakpoint(retina) {
  .sidebar-panel-cart {
    .delivery-truck-icon {
      background-image: url('/images/delivery%20truck@2x.png');
        background-size: 60px 34px;
    }
  }
}


@media only screen and (min-width: 769px) and (max-width: 1023px){
  .sidebar-panel-cart .free-delivery
  {
    .truck-icon-container
    {
      display: none !important;
    }
    // override the grid layout between these sizes
    .info-container
    {
      -webkit-flex: 0 0 100%;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%;
      max-width: 100%;
    }

  }
}

.stock-taken-warning-header {
  padding: 4px 7px;
  font-size: 12px;
  font-weight: bold;
  margin: -1rem -1rem 1rem -1rem;
  background-color: get-color(warning);
  color: black;
}


.sidebar-toggle-button
{
  font-weight: bold;
  text-align: center;
  color: get-color(secondary);
  display: block;
  width: rem-calc(20px);
  height: rem-calc(20px);
  background: $white;
  padding: rem-calc(0 25px 0 0);

  &:hover
  {
    color: get-color(primary);
    outline: none;
  }

  &:active, &:focus
  {
    outline: none !important;
  }
}
