.saved-cards-list {
  margin-bottom: rem-calc(20px);
}

.saved-card-container {
  font-weight: normal;
  margin-bottom: rem-calc(15px);

  // is: label
  .saved-card-radio-container {

    display: inline-block;
    background-color: white;
    padding: rem-calc(2px 12px);
    border-radius: $panel-small-radius;
    width: rem-calc(520);
    max-width: rem-calc(520px);

    span {
      vertical-align: middle;
      display: inline-block;
      margin-right: rem-calc(20px);
      font-weight: normal;

      &.card-brand {
        width: rem-calc(75);

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      
      &.cardholder-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 0;
        max-width: rem-calc(180);
      }
    }
  }

  .help-text.delete-card {
    font-weight: normal;
    display: inline-block;
    margin-left: 20px;
  }
}

.saved-card-number {
  white-space: nowrap;
  font-family: monospace;
}

// label.saved-card-radio-container {
//   padding: 0;
//   margin: 0;
// }



/* Note that this is used in the checkout and the order-payment view
   For that reason we include here an override for the order-payment view (which is narrower) 
*/

.order-payment-panel {
  .help-text.delete-card {
    font-weight: normal;
    display: block;
    margin: rem-calc(6 0 0 0);
    text-align: right;
  }
  
  .card-errors {
    margin-bottom: rem-calc(30px);
  }
}