.contact-system {
  &.bot-floating {
    display: block;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 200;
    transition: bottom 250ms ease-in-out, right 250ms ease-in-out;

    &.loading {
      right: -100px;
      @include breakpoint(large) {
        right: -159px;
      }
    }

    &.shift-up {
      bottom: 80px;
    }
  }
  // see below for .bot-sidebar alterations on below components
  .contact-show-button {
    display: block;
    position: absolute;
    width: 85px;
    height: 85px;
    overflow: hidden;
    bottom: 0;
    right: 0;
    text-indent: 100%;
    white-space: nowrap;
    background-image: url("/images/bot-button@2x.png");
    background-size: 85px 170px;
    &.support-team-online {
      background-image: url("/images/bot-button-live@2x.png");
      background-size: 85px 170px;
    }

    background-position: 0 0;
    background-attachment: norepeat;
    z-index: 202;

    @include breakpoint(large) {
      width: 144px;
      background-image: url("/images/bot-button-wide@2x.png");
      background-size: 144px 170px;
      &.support-team-online {
        width: 155px;
        background-image: url("/images/bot-button-live-wide@2x.png");
        background-size: 155px 170px;
      }
    }

    &:hover {
      background-position: 0 -85px;
    }

  }
  .speech-bubble {
    display: none;
    width: rem-calc(380px);
    position: absolute;
    bottom: 100px;
    right: rem-calc(15px);
    background: #fff;
    border-radius: 0.4em;
    z-index: 203;
    user-select: none;
    padding: rem-calc(16px 24px 16px 20px);
    box-shadow: 3px 3px 14px rgba(0, 0, 0, 0.35);

    &.show {
      display: block;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 50px;
      width: 0;
      height: 0;
      border: 0.813em solid transparent;
      border-top-color: #fff;
      border-bottom: 0;
      border-right: 0;
      margin-left: -0.406em;
      margin-bottom: -0.812em;
    }

    p {
      strong {
        color: get-color(primary);
      }

      user-select: none;
      font-size: rem-calc(14px);
      padding: 0;
      margin: 0;
      line-height: rem-calc(22px);
      overflow: hidden;
    }

    .close-button {
      display: block;
      position: absolute;
      overflow: hidden;
      text-indent: 100%;
      white-space: nowrap;
      right: rem-calc(7px);
      top: rem-calc(7px);
      width: 17px;
      height: 17px;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
      &:before,
      &:after {
        position: absolute;
        top: 0;
        left: 8px;
        content: " ";
        height: 18px;
        width: 2px;
        background-color: get-color(primary);
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }

  .contact-panel {
    display: block;
    position: absolute;
    width: 380px;
    height: 550px;
    background: $white;
    overflow: hidden;
    bottom: rem-calc(15px);
    right: rem-calc(15px);
    border-radius: 8px;
    box-shadow: 3px 3px 14px rgba(0, 0, 0, 0.35);
  }

  &.bot-floating .contact-panel
  {
    z-index: 201;
  }

  .contact-panel-header {
    background: get-color(primary);
    padding: rem-calc(30px 15px 12px 15px);

    h1 {
      color: $white;
      font-size: rem-calc(22px);
      padding: 0;
      margin: 0;
    }
  }

  .contact-panel.priority-customer .contact-panel-header {
    background: $support-green !important;
  }

  .live-header
  {
    padding-left: rem-calc(15px);
    background-color: _darken($support-green, 10%);
    color: #fff;
    overflow: hidden;
    height: rem-calc(18px);
    font-size: 12px;
    font-weight: normal;
  }

  .contact-panel-close-button {
    display: block;
    position: absolute;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    right: rem-calc(10px);
    top: rem-calc(10px);
    width: 24px;
    height: 24px;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
    &:before,
    &:after {
      position: absolute;
      top: 0;
      left: 12px;
      content: " ";
      height: 25px;
      width: 2px;
      background-color: #fff;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }

  .contact-content {
    p {
      margin: rem-calc(15px);
      font-size: rem-calc(16px);
    }
    &.contact-errors p {
      color: get-color(alert);
    }

    button {
      display: inline-block;
      margin-left: rem-calc(15px);
    }

    &.contact-transcript {
      transition: bottom 250ms ease-in-out;
      display: block;
      position: absolute;
      width: 100%;
      top: rem-calc(72px);
      bottom: rem-calc(90px);
      overflow: hidden;
      overflow-y: scroll;

      &.menu-only-mode {
        bottom: 0;
      }
    }

    .comment {
      margin: rem-calc(15px 15px 20px 15px);
      .username {
        font-size: rem-calc(13px);
        font-weight: bold;
      }
      p {
        border-radius: 5px;
        padding: 5px 8px;
        background-color: _transparentize(get-color(primary), 0.8);
        display: inline-block;
        font-size: rem-calc(14px);
        margin: rem-calc(0 25px 0 0);
      }

      &.comment-wrp {
        .username {
          color: get-color(primary);
        }

        &.wrp-staff {
          .username {
            color: $support-green;
          }
          p {
            background-color: _transparentize($support-green, 0.8);
          }
        }
      }

      &.comment-customer {
        @include clearfix;
        text-align: right;
        .username {
          text-align: right;
          color: get-color(secondary);
        }
        p {
          background-color: _transparentize(get-color(secondary), 0.8);
          text-align: left;
          margin: rem-calc(0 0 0 25px);
        }
      }

      .video-thumbnail {
        margin-top: rem-calc(15px);
      }
    }

    // menu is inside contact-content.contact-transcript scrolling
    // but we don't need to nest the CSS
    .bot-menu-container {
      display: grid;
      grid-template-rows: 1fr;
      // transition to max-height, 
      transition: grid-template-rows 300ms ease-in-out;
      &.disable {
        grid-template-rows: 0fr;
      }
    }

    .bot-menu {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      /* Aligns buttons to the right */
      margin: rem-calc(15px 15px 14px 15px);

      .bot-menu-button {
        box-sizing: border-box;
        align-self: flex-end;
        width: fit-content;
        white-space: nowrap;
        margin: rem-calc(0 0 12px 0);

        &:last-child {
          margin-bottom: 0;
        }
      }

    }

    .bot-menu-button {
      cursor: pointer;
      transition: background-color 250ms ease-in-out, opacity 250ms ease-in-out;
      background-color: $question-tag;

      &:hover {
        background-color: $question-tag-hover;
      }

      &.start-again {
        background-color: $question-tag-primary;
        &:hover {
          background-color: $question-tag-primary-hover;
        }
      }


      &:disabled {
        opacity: 0;
      }
  
      border-radius: 6px;
      color: #fff;
      display: inline-block;
      font-size: rem-calc(13px);
      line-height: 140%;
      padding: 4px 10px;
    }
    
  }

  // variation for when team is live
  .contact-panel.priority-customer {
    .contact-content.contact-transcript {
      top: 90px;
    }
  }

  // - - sidebar variation, in help centre - - //
  &.bot-sidebar
  {
    .contact-show-button {
      display: none;
    }

    .speech-bubble.show {
      display: none;
    }

    .contact-panel {
      display: block;
      position: relative;
      left: 0;  
      top: 0;
      bottom: auto;
      right: auto;
      width: 100%;
      height: 550px;
      background: $white;
      overflow: hidden;
      border-radius: 0;
      box-shadow: none;
    }

    .contact-panel-header {
      background: get-color(primary);
      box-sizing: border-box;
      height: rem-calc(40px);
      padding: rem-calc(8px 15px 10px);
  
      h1 {
        color: $white;
        font-size: rem-calc(18px);
        padding: 0;
        margin: 0;
      }
    }

    .contact-panel-close-button {
      display: none;
    }

    .contact-content.contact-transcript {
      top: rem-calc(40px);
    }

    // sidebar variation for when team is live
    .contact-panel.priority-customer {
      .contact-content.contact-transcript {
        top: rem-calc(58px);
      }
    }
    
  } // end sidebar variations

  .busy-indicator {
    width: 10px;
    height: 10px;
    background-color: red;
    position: absolute;
    bottom: 90px;
    z-index: 300;
  }


  .contact-panel-input {
    transition: height 250ms ease-in-out;
    position: absolute;
    width: 100%;
    height: rem-calc(90px);
    bottom: 0;
    left: 0;
    background-color: #fff;
    box-shadow: 0 -3px 35px rgba(0, 0, 0, 0.2);

    textarea {
      display: block;
      margin: 0;
      height: rem-calc(90px);
      resize: none;
      background-color: transparent;
      border: none;
      box-shadow: none;

      &:focus {
        border: none;
        box-shadow: none;
      }
    }

    .suggested-questions {
      position: absolute;
      bottom: 0;
      height: auto;
      left: 0;
      right: 0;
      text-align: center;
      @include ng_fade_show_hide_timed(0.2s);

      .question-tag
      {
        display: inline-block;
        white-space: nowrap;
        position: relative;
        top: rem-calc(-5px);

        &.start-again {
          background-color: $question-tag-primary;
          &:hover {
            background-color: $question-tag-primary-hover;
          }
        }
      }
    }

    .question-tag
    {
      transition: background-color 250ms ease-in-out;
      background-color: $question-tag;

      &:hover
      {
        background-color: $question-tag-hover;
      }
  
      border-radius: 6px;
      color: #fff;
      display: inline-block;
      font-size: 13px;
      line-height: 140%;
      margin: rem-calc(0 10px 5px 0);
      padding: 2px 6px;

    }
    
    // still in .contact-panel-input
    &.hide {
      overflow: hidden;
      height: 0;
      .suggested-questions {
        display: none;
      }
    }
  
  } // end .contact-panel-input

  @include breakpoint(small only) {
    &.bot-floating {
      pointer-events: none;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;

      &.shift-up {
        bottom: 0; // there is no 'back to top' button on mobile
      }

      .contact-panel {
        pointer-events: auto;
        width: auto;
        height: auto;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        border-radius: 0px;
      }

      .contact-show-button {
        pointer-events: auto;
      }
    }

    .speech-bubble {
      width: auto;
      left: rem-calc(15px);
      right: rem-calc(15px);
      pointer-events: auto;     
    }
  }

  
  

}
