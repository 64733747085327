.status-bar {

  background-color: #333;
  background: linear-gradient(#333, #444);
  padding: 9px 0 7px 0;
  text-align: center;

  .status-bar-flex-container {
    display: flex;
    justify-content: space-between;
  }

  .support-icon {
    display: inline-block;
    margin: 0;
    padding: rem-calc(0 12px 0 0);
    box-sizing: border-box;
    text-decoration: none;
    outline: none;
    width: rem-calc(44px);

    img {
      max-width: 100%;
      display: block;
      margin: 0;
    }

    span {
      display: block;
      text-align: center;
      color: $white;
    }

    &.inactive {
      opacity: 0.2;
    }

    &.error {
      pointer-events: none;
    }
  }

  p {
    color: #ccc;
    display: block;
    font-size: rem-calc(15px);
    line-height: 1.5;
    margin: 0;
    padding: 0.4rem auto;
    text-align: center;
  }

  .button {
    display: block;
    margin: 0.4rem auto 0.4rem auto;
    max-width: 12rem;
    text-align: center;
  }

  .stock-view-toggler,
  .right-content {
    display: table;
    border-spacing: 0.5em;

    >* {
      display: table-cell;
      vertical-align: middle;

      &.logged-in-info {
        padding-right: rem-calc(10px);
        position: relative;
        top: rem-calc(-3px);
      }

      &.button:not(:last-child) {
        margin-right: rem-calc(10px);
      }
    }
  }

  .stock-view-toggler {
    >label a {
      color: #ccc;
      font-size: rem-calc(15px);
      font-weight: normal;
    }
  }

  .switch {
    margin-bottom: 0;
  }

  .switch-paddle {
    background: $switch-background-active;
  }

  .stock-view-switch-override {
    text-decoration: none;
  }


  &.warning {
    background-color: #611;
    background: linear-gradient(#611, #811);
  }

  &.edit-mode {

    p,
    a,
    a:hover {
      color: #000;
    }

    .button {
      display: inline-block;
    }

    a {
      text-decoration: underline;
    }

    background-color: #FCC528;
    background: linear-gradient(#FCC528, #e0af23);

  }

  @include breakpoint(medium) {

    p {
      display: inline-block;
      padding: rem-calc(5px) rem-calc(15px) 0 0;
      text-align: left;
    }

    a.button {
      display: inline-block;
      margin: 0 0 0.1rem 0;
    }

  }


}