@use 'sass:color';

.checkout {

  // panels  / elements hidden by default
  #find-customer,
  #admin-create-customer,
  #selected-customer,
  #contacts,
  #admin-delivery-details,
  #delivery-options,
  #collection-info,
  #checkout-cart-summary,
  #admin-checkout-payment-options,
  #payment-reference-container,
  #cardholder_details
  {
    display: none;
  }

  // these items are in the sidebar
  #checkout-customer-flag,
  #checkbox-deliver-previous-container
  {
    display: none;
  }
  

  h4
  {
    font-size: rem-calc(16px);
  }

  .tooltip
  {
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  .xhr-password
  {
    margin-bottom: 1rem;
  }


  .existing-address-items strong
  {
    color: #000;
  }


  .callout
  {
    &.alert
    {
      border: 1px solid get-color(alert);
    }

    &.secondary
    {
      border: 1px solid color.scale(get-color(secondary), $lightness: 50%);
    }
  }

  .customer-search-busy
  {
    padding-top: rem-calc(5px);
    position: relative;
  }

  .spinner-container
  {
    position: absolute;
    top: rem-calc(20px);
    left: 50%;
    margin-left: rem-calc(-20px);
    z-index: 1000;
    overflow: visible;
  }

  .customer-search-results
  {
    padding-top: rem-calc(5px);
    max-height: rem-calc(420px);
    overflow: hidden;
    overflow-y: auto;
    p.results-heading
    {
      font-size: rem-calc(14px);
      font-weight: bold;
      margin-top: rem-calc(12px);
      margin-bottom: 5px;
    }

    .result
    {
      // font-size: rem-calc(13px);
      background-color: $body-background;
      margin: rem-calc(0 0 3px 0);
      border-radius: rem-calc(2px);;

      &:hover
      {
        background-color: _darken($body-background, 10%);
      }

      .fi-star
      {
        color: _darken($specials-color, 20%);
      }

      > div
      {
        
        &:first-child
        {
          padding-left: rem-calc(10px);
        }
        &.text-col
        {
          font-size: rem-calc(13px);
          padding-top: rem-calc(8px);
          line-height: rem-calc(13px);
          overflow: hidden;
          padding-bottom: 8px;
          .help-text {
            font-size: 12px;
          }

          &:hover
          {
            overflow-x: visible;
          }

        }

        &.button-col
        {
          text-align: right;
          padding-right: rem-calc(6px);

          .button.tiny
          {
            margin-top: rem-calc(8px);
            padding: rem-calc(7px 10px);
          }
        }
      }



      .button
      {
        margin: rem-calc(2px);
        &:hover
        {
          text-decoration: none;
        }
      }



      a.has-tip
      {
        border-bottom: none;
      }

    }


  }


  .callout
  {
    position: relative;
    .admin-start-again-button, .back-to-cart-button, .admin-link-buttons-container
    {
      display: block;
      margin: 0;
      position: absolute;
      right: rem-calc(15px);
      top: rem-calc(15px);
    }
  }

  .admin-checkout-summary
  {
    .emphasize
    {
      font-size: rem-calc(18px);
    }
  }

  .admin-link-buttons-container
  {
    a.button
    {
      vertical-align: top;
      display: inline-block;
      &:last-child
      {
        margin: 0;
      }
    }
  }

  // also has .row
  
  .existing-contacts
  {
    // is inside div.small-6.columns
    .radio-check.existing-contact
    {
      background: $body-background;
      border-radius: 2px;
      border: 1px solid $body-background;
      bottom: auto;
      color:  #666;
      display: block;
      display: block;
      font-size: rem-calc(14px);
      left: 0;
      line-height: 120%;
      margin-bottom: rem-calc(15px);
      overflow: hidden;
      padding: 7px 8px 7px 22px;
      position: relative;
      right: auto;
      text-align: left;
      text-overflow: ellipsis;
      top: 0;
      white-space: nowrap;
      width: 100%;

      &.selected
      {
        color: #002840;
        background-color: #fff;
      }

      .contact-checkbox
      {
        display: block;
        left: 4px;
        margin: 0;
        position: absolute;
        top: 7px;
      }

    }
  }

  .new-contact-table
  {
    th
    {
      font-weight: normal;
      padding: rem-calc(4px 8px 3px 14px);
      text-align: left;
      vertical-align: bottom;
      width: 31%;

      &:nth-child(4)
      {
        width: 7%;
      }

    }

    td
    {
      vertical-align: top;
      background-color: $body-background;
      padding-bottom: 0;

      label.error
      {
        display: none !important; //@NOTE required to override the inline style added by the checkout validator

      }

      input.error
      {
        @include field_invalid;
        background-color: #fff !important; // @NOTE the mixin uses important (annoyingly) so it is needed here to override that
      }
    }
  }



  .customer-flag-panel
  {
    &.alert-green
    {
      background-color: $panel-green; // see note in _settings.scss
    }

    &.alert-red
    {
      background-color: _lighten(get-color(alert), 24%);
    }
  }


  #delivery-options, #collection-info, #new-password-container
  {
    display: none;
    &.admin-display-override, &.persistent
    {
      display: block;
    }
  }


  #ng-checkout-billing-addresss .postcode-textfield
  {
    max-width: 20ex;
  }

  .checkout-errors
  {
    div.danger:last-child p, div.success:last-child p
    {
      margin-bottom: 0;
    }
  }

  .row.existing-or-new-customer
  {
    padding-top: rem-calc(8px);
    input[type="radio"]
    {
      display: none;
    }
    button.button
    {
      margin: 0;

      &.subdued
      {
        opacity: 0.5;
      }
    }
    label
    {
      font-size: rem-calc(14px);
    }
  }

  .checkout-textarea
  {
    // max-width: 40ex;
    width: 40ex;
  }



  .checkout-textarea:disabled, .postcode-textfield:disabled
  {
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
  }

  .radio-options label
  {
    display: inline-block;
    font-size: rem-calc(15px);
    line-height: 1.8;
    &:first-child
    {
      margin-right: 2ex;
    }
    input[type="radio"]
    {
      vertical-align: middle;
      display: inline-block;
      margin: 0 2px 0 0;
      position: relative;
      top: -2px;
    }
  }

  .payment-fields, .field-container
  {
    &.delivery-notes-container {
      .form-text-input {
        display: block;
      }
    }

    font-size: rem-calc(15px);
    line-height: 1.8;

    &.delivery-info-for-customer
    {
      max-width: rem-calc(380px);
    }

    label
    {
      font-size: rem-calc(15px);

      &.disabled
      {
        color: $disabled-color;
      }
    }

    input[type="radio"], input[type="checkbox"]
    {
      vertical-align: middle;
      display: inline-block;
      margin: 0 2px 0 0;
      position: relative;
      top: -2px;
    }

    .row.group-end, .grid-x.group-end
    {
      margin-bottom: rem-calc(15px);
    }

    .submit-container
    {
      button.button
      {
        margin-bottom: 0;
      }
    }
  }

  .field-container.account-type-options
  {
    margin-bottom: rem-calc(12px);
  }

  .delivery-postcode
  {
    margin-bottom: rem-calc(20px);
  }

  .address-module
  {
    width: 40ex;
  }

  .embedded-postcode-module
  {
    background-color: $white;
    padding: 3px 12px;

    label
    {
      color: #000;
    }
    .current-address
    {
      font-size: rem-calc(13px);
      margin-left: rem-calc(10px);
      white-space: pre;
    }

    .edit-postcode-container
    {
      margin-top: rem-calc(8px);
      margin-bottom: 0;
      text-align: left;
      label
      {
        display: block;
      }

      .postcode-lookup-container
      {
        margin-bottom: rem-calc(15px);
      }
    }

    a
    {
      font-weight: normal;
    }
  }

  .postcode-search-results
  {
    margin: rem-calc(0 0 10px 0);
  }

  // .change-address-link
  // {
  //   display: inline-block;
  //   margin-top: rem-calc(12px);
  // }


  #email_warning_container
  {
    padding-left: 11.5rem;
    display: none;
    hr
    {
      color: get-color(secondary);
      background-color: get-color(secondary);
      height: 1px;
      border: 0;
    }
    p.danger
    {
      margin-bottom: 5px;
    }

    button, a
    {
      display: inline-block;
      margin: 0 0 0 8px;
    }

    .forgot-link
    {
      font-size: rem-calc(13px);
      margin-bottom: 40px;
    }
  }


  .email-warning-container
  {
    padding-top: rem-calc(5px);
    position: relative;

    .checking.help-text
    {
      padding-left: rem-calc(33px);
      position: relative;
    }

    .spinner-container
    {
      top: rem-calc(12px);
      left: rem-calc(13px);
      margin-left: 0;
    }
  }

  .form-container
  {
    .form-label-text
    {
      display: inline-block;
      min-width: rem-calc(180px);
    }
    .form-text-input, .field-container
    {
      display: inline-block;
      vertical-align: top;
    }

    .hint-text.delivery-aim
    {
      margin: 0;
      &.delivery-aim-after
      {
        margin-top: rem-calc(-13px);
      }
    }
    #delivery_estimate_text-error
    {
      display:inline;
    }
  }

  #checkout-cart-summary
  {
    p.hint-text
    {
      margin-bottom: 0;
    }
  }

  .order-summary
  {
    margin-top: rem-calc(25px);
    font-size: rem-calc(14px);
  }

  .price-breakdown, .ppm-info, .trade-savings
  {
    font-size: 85%;
    white-space: nowrap;
    color: _darken(get-color(secondary), 10%);
  }

  .trade-savings, .trade-savings-total
  {
    color: _darken(get-color(success), 8%);
  }

  .checkout-table
  {
    th
    {
      text-align: left;
    }

    td
    {
      vertical-align: top;
    }

    td.totals.total, td.total
    {
      font-weight: bold;
    }

    .parts-summary, .size-summary, .lengths-notes
    {
      color: _darken(get-color(secondary), 20%);
    }

    .samples-list
    {
      .sample
      {
        display: inline-block;
        border-radius: $panel-small-radius;
        background: #ccc;
        font-size: rem-calc(12px);
        font-weight: bold;
        padding: 1px 4px;
        margin: 4px 6px 2px 0;

      }
    }
  }


  .paypal-form
  {
    // paypal
    .paybutton
    {
      width: 140px;

      &[disabled]
      {
        background-color: transparent;
        opacity: 0.4;
      }
    }
  }


  label.terms-checkbox-label
  {
    margin-bottom: 15px;
    height: rem-calc(25px);
  }
  .terms_and_returns
  {
    margin-bottom: rem-calc(15px);
  }

  // paragraph in success page
  .fb-link
  {
    padding-top: 6px;
  }

  .ajax-password
  {
    margin-bottom: 30px;
  }

}




@include breakpoint(small only) {
  .checkout {
    .form-container
    {

      .address-module
      {
        width: auto;
      }

    
      #email_warning_container
      {
        padding-left: 0;
        display: none;
      }


      .form-text-input, .field-container
      {
        display: block;
      }

      .checkout-textarea
      {
        // max-width: 40ex;
        width: auto;
      }
    }
  }
}
