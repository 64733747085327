.payments
{
  .top-bar
  {
    margin-bottom: 0;
    min-height: rem-calc(20px);
    padding: rem-calc(12px 0);
    .logo {
      // padding-right: 1rem;
      width: 100px;
      > h1
      {
        // margin: 0;
        // display: block;
        // padding: 0;
        a {
          // display: block;
          // background: url('../images/WRP%20logo.png') no-repeat;
          width: 100px;
          height: 35px;
        }
      }
    }

    .top-bar-left
    {
      min-height: rem-calc(0px);
    }
    .top-bar-right
    {
      top: 0;
      padding: 0;
      .menu
      {
        display: block;
        margin: 0;
        li:nth-child(2) .button{
          margin-left: rem-calc(15px);
        }
      }
      .button
      {
        margin: 0;
        display: inline-block;
      }
      
    }
  }

  @include breakpoint(small only) {
    .top-bar-container-row
    {
      margin-bottom: rem-calc(20px);
    }
  }

  table, tbody
  {
    border: none;
    tr, td
    {
      background-color: transparent;
    }
  }

  
}
