
.error-page
{
  padding-top: rem-calc(20px);

  h1
  {
    margin-bottom: rem-calc(15px);
  }
}
