.timber-types
{
  .single-timber-row
  {
    margin-bottom: rem-calc(20px);

    image
    {
      display: block;
      max-width: 100%;
    }

    p
    {
      font-size: rem-calc(15px);
    }

    @include breakpoint(small only) {
      h2
      {
        margin-top: rem-calc(15px);
      }
    }
  }
}
