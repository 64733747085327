.featherlight-next,
.featherlight-previous {
  &:hover {
    background-color: transparent;
    
  }
  span {
    color: $white !important;
    text-shadow: none;
    -webkit-text-stroke: 2px $dark-gray;
  }
}
