body.home
{
  .lead
  {
    position: relative;
    .hint-text
    {
      position: absolute;
      top: -1rem;
      left: 0;
    }
  }
}


.homepage-hero-panel, .homepage-promo-panel
{
  .special-offers, .special-run
  {
    align-items: stretch;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: rem-calc(400px);
    position: relative;
    z-index: 0;

    &::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0.4;
      z-index: -2;
      background-image: url("/images/promos/wrp-timber-mouldings-special-offers-promo-background@2x.jpg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }

  &.trade-mill
  {
    background: $primary-color-darker;
    color: $white;

    background-image: url("/images/homepage-brand-edge-join@2x.png");
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 64px 160px;
    position: relative;
    z-index: 0;

    .promo-container {
      box-sizing: border-box;
      margin: rem-calc(40px 20px 40px 75px);
      position: relative;
    }
  
    .promo-image-column {
      position: relative;
    }
  
    .promo-image-container {
      position: absolute;
      top: 0;
      right: 10px;
      bottom: 0;
      left: 0;
      background-image: url("/images/moulding-profile-header@2x.jpg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    .h1-hero
    {
      padding: 0;
      margin: rem-calc(0 0 15px 0);
      color: $white;
      font-weight: bold;
    }

    ul
    {
      margin: rem-calc(0 0 15px 0);

      li
      {
        margin-left: rem-calc(17px);
      }
    }

    p.smalltext
    {
      margin: rem-calc(0 0 15px 0);
      padding: 0;
    }

    .button.large
    {
      // background: #0be0c0;
      margin: rem-calc(0);
    }


  }

  // invisible hotspot covering whole panel
  // @NOTE tried to change the whole div into an <a> tag but it did something to the layout i could not fix by setting it back to display: block or display: flex
  .panel-button
  {
    bottom: 0;
    display: block;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-indent: 100%;
    top: 0;
    white-space: nowrap;
    z-index: 2;
  }

  p
  {
    &.subhead
    {
      font-size: rem-calc(18px);
      margin: rem-calc(0 0 8px 5px);
      padding-bottom: 0;
    }

    &.smalltext
    {
      font-size: rem-calc(14px);
      margin: rem-calc(0 0 0 5px);
      padding-top: 0;
    }
  }

  .button.large
  {
    margin: rem-calc(20px 0 0 15px);
  }

  .copy-container
  {
    position: relative;
    top: rem-calc(-5px);
    overflow: hidden;
    display: block;
    min-height: rem-calc(200px);
    padding: rem-calc(30px 15px 30px 50px);
    width: 100%;
    h1, p
    {
      text-align: left;
    }

    .h1-hero a
    {
      color: $primary-shade-2;
      font-weight: normal;
    }
  }
}


.homepage-promo-panel
{
  .special-offers, .special-run
  {
    min-height: rem-calc(300px);
    &::before {
      background-image: url("/images/promos/wrp-timber-mouldings-special-offers-promo-background-small@2x.jpg");
      background-size: cover;
    }

    .copy-container
    {
      // position: relative;
      // top: rem-calc(-5px);
      // overflow: hidden;
      // display: block;
      min-height: rem-calc(100px);
      padding: rem-calc(10px 15px 20px 30px);

      .h1-hero
      {
        font-size: rem-calc(38px);
      }
    }

  }

  .special-run {

    &::before {
      background-image: none;
      background-size: cover;
    }
    background: repeating-linear-gradient(-45deg, $trade_stripe_light, $trade_stripe_light 10px, $trade_stripe_dark 10px, $trade_stripe_dark 20px);;
  }

}


@include breakpoint(medium only)
{
  .row.homepage-categories, .row.homepage-profiles
  {
    margin-bottom: rem-calc(35px);
  }
}



@include breakpoint(small only)
{

  .homepage-hero-panel
  {
    &.trade-mill
    {
      position: relative;

      background-color: #000;
      background-image: none;
      
      &::before
      {
        z-index: -1;
        opacity: 0.5;
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url("/images/moulding-profile-header@2x.jpg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
      }
    
      &::after
      {
        content: " ";
        display: block;
        width: 64px;
        height: 160px;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url("/images/homepage-brand-edge-join-blue@2x.png");
        background-repeat: no-repeat;
        background-position: top left;
        background-size: 64px 160px;
        z-index: 2;
      }
    }
    


    .special-offers, .special-run
    {
      min-height: 300px;
      .copy-container
      {
        padding: rem-calc(30px 15px);
      }
    }
  }

  .row.homepage-categories, .row.homepage-profiles
  {
    margin-bottom: rem-calc(30px);
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

.margin-top-large.homepage
{
  a.chevron.lead-balance
  {
    margin-top: rem-calc(5px);
  }
}
