.postcode-lookup-container, .address-module
{
  .postcode-textfield
  {
    max-width: 40ex;
    line-height: 140%;
  }

  .postcode-search-results
  {
    font-size: rem-calc(13px);
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
    line-height: 1.5;

    a
    {
      display: block;
      font-weight: normal;
      overflow: hidden;
      padding: 2px 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;

      // bold the span content on postcode type results (groups of addresses)
      &.Postcode
      {
        .sec1
        {
          font-weight: bold;
        }
      }

      &.Address
      {
        .sec2
        {
          opacity: 0.65;
        }
      }

      &.set-manual
      {
        margin-top: rem-calc(8px);
        font-size: rem-calc(15px);
        .sec1
        {
          opacity: 0.65;
        }
        .sec2
        {
          font-weight: bold;
        }
      }

      &:hover
      {
        text-decoration: none;

      }


    }
  }

  .manual-mode
  {
    h4
    {
      font-size: rem-calc(14px);
    }

    .address-textarea
    {
      margin-bottom: 8px;
    }

    .postcode-textfield
    {
      margin-bottom: 15px;
    }

    .small-text.danger
    {
      display: block;
      position: relative;
      top: -12px;
    }

    .hint-text
    {
      color: #fff;
      opacity: 0.5;
      margin-top: -8px;

    }
  }
}

.delivery-address-edit
{
  font-weight: bold;
  display: inline-block;
  margin-left: 8px;
}
