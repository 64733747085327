.register-form {
  .checkbox-label {
    max-width: rem-calc(580px);
    line-height: rem-calc(22px);

    &.terms-consent {
      margin: rem-calc(10px) 0 rem-calc(20px) 0;
    }

    span {
      vertical-align: top;
      max-width: rem-calc(400px);
      width: calc(100% - 35px);
      display: inline-block;
    }
  }

  .radio-options {
    margin: rem-calc(0 0 15px 0);

    label {
      display: inline-block;
      font-size: rem-calc(15px);
      line-height: 1.8;
      &:first-child {
        margin-right: 2ex;
      }
      input[type="radio"] {
        vertical-align: middle;
        display: inline-block;
        margin: 0 2px 0 0;
        position: relative;
        top: -2px;
      }
    }
  }
  @include breakpoint(medium up) {
    &.form-container {
      .form-label-text {
        display: inline-block;
        min-width: rem-calc(180px);
      }
      .form-text-input,
      .field-container {
        display: inline-block;
        vertical-align: top;
      }

      .hint-text.delivery-aim {
        margin: 0;
        &.delivery-aim-after {
          margin-top: rem-calc(-13px);
        }
      }
      #delivery_estimate_text-error {
        display: inline;
      }
      .help-text {
        margin-top: -15px;
      }
    }
  }
}
