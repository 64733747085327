.menu-desktop-bar {

  margin-bottom: 2rem;
  background: $primary-shade-1;
  box-shadow: inset 0px 4px 0px 0px _transparentize($primary-shade-2, 0.81), inset 0px 6px 0px 0px _transparentize($primary-shade-2, 0.7);

  .menu {
    background-color: $primary-shade-2;
    font-size: 0.875rem;
    @include breakpoint(large) {
      margin-left: 0;
      margin-right: 0;
    }
    > li {
      padding-left: 0;
      padding-right: 0;
      > a {
        color: $white;
        background-color: transparent;
        padding: rem-calc(18) 0;
        text-align: center;
        &:hover,
        &:focus {
          color: $white;
          background-color: _transparentize(get-color(primary), 0.75) !important;
          text-decoration: none;
        }
      }

      &.is-active > a {
        color: $white;
      }

      &.home-menuitem {

        // home button is a square
        flex-shrink: 1;
        width: rem-calc(57);
        min-width: rem-calc(57);
        max-width: rem-calc(57);

        > a {
          background-image: url('../images/Home%20icon.png');
          background-size: 21px 20px;
          background-repeat:  no-repeat;
          background-position: center;
          color: transparent;
          width: 21px;
          padding: rem-calc(18) rem-calc(18);
          overflow: hidden;
          box-sizing: content-box;
        }

      }

      &.mouldings-in-stock-menuitem > a, &.profile-shapes-menuitem > a {
        color: get-color(success);
      }

      &.mouldings-specials-menuitem > a
      {
        color: get-color(warning);
      }

    }

    li.special-run-menuitem {
      display: none;
    }

    .has-submenu {
      > a
      {
        pointer-events: none;
      }
      &.is-active {
        background: $primary-shade-3;
      }
    }


    &.foundation-not-ready
    {
      // hide nested menus
      li ul.menu
      {
        display: none;
      }
    }


    &.show-special-run {
      li.special-run-menuitem {
        display: block;
      }
    }


  }
  .dropdown.menu .submenu {

    background: $primary-shade-3;
    border: none;
    z-index: 2;

    &.first-sub {
      right: 0;
      & ~ .first-sub {
        left: auto;
      }
    }
  }
  .dropdown.menu .is-dropdown-submenu-parent.is-down-arrow > a::after {
    top: rem-calc(18);
  }

  .is-submenu-item.has-metadata
  {
    a
    {
      min-height: 50px;
      padding: rem-calc(9px 0 15px 0) !important;
      span {
        font-size: 85%;
        font-weight: normal;
        display: block;
        overflow: visible;
        // height: 0;
        position: relative;
        top: 5px;
      }
    }
    
  }

  // now the same for .has-thumbnail
  .is-submenu-item.has-thumbnail
  {
    a
    {
      position: relative;
      overflow: hidden;
      .img-container
      {
        position: absolute;
        top: 8px;
        left: 8px;
        pointer-events: none;
        width: 46px;
        overflow: hidden;
      }
      img
      {
        display: block;
        max-width: 144%;
      }

      @media screen and (max-width: 1080px) {
        .img-container
        {
          width: 34px;
        }
        img
        {
          display: block;
          max-width: 206%;
        }
      }
  
  
      @media screen and (max-width: 1023px) {
        .img-container
        {
          display: none;
        }
      }
      
      
    }

    
  }

}

@include breakpoint(medium) {
  .menu.vertical > li {
    display: inline-block;
  }
}

@include breakpoint(small only) {
  .menu.vertical > li {
    &.has-metadata {
      a
      {
        height: 50px;
        span {
          position: relative;
          top: 4px;
          font-size: 75%;
          display: block;
        }
      }
      
    }
    &.has-thumbnail
    { 
      a
      {
        position: relative;
        padding-left: 71px;
        padding-top: 7px;
      }
      
      .img-contaner{
        position: absolute;
        left: 12px;
        top: 8px;
        width: 48px;
        overflow: hidden;
      }
      img
        {
          display: block;
          max-width: 125%;
        }
    }
  }
}

.submenu-container {
  background: red;
}

.top-bar-left,
.top-bar-right {
  @include breakpoint(small only) {
    width: auto;
  }
}

.is-drilldown-submenu {
  background-color: $primary-shade-3;
}
.off-canvas {
  a {
    font-weight: $global-weight-normal;
    color: $white;
  }
}

.breadcrumbs {
  background-color: $white;
  padding: 0.5625em 0.875em;
  li {
    font-weight: $global-weight-bold;
    &:not(:last-child)::after {
      content: ">";
    }
  }
  @include breakpoint(small only) {
    margin-left: rem-calc(map-get($grid-column-gutter,small))*-0.5;
    margin-right: rem-calc(map-get($grid-column-gutter,small))*-0.5;
    padding: 0;
    li {
      font-weight: $global-weight-normal;
      font-size: rem-calc(16);
      line-height: 3;

      &:not(:nth-last-child(-n+2)){
        display: none;
      }

      &:last-child {
        margin-left: 0.5rem;
      }

      &:nth-last-child(2) {
        width: 3rem;
        > a, a:hover, a:focus {
          color: transparent;
          white-space: nowrap;
        }
        background: url('/images/chevron.png') no-repeat center $primary-shade-2;
      }

      &::after {
        content: none !important;
      }
    }
  }
}



.cycle-products
{
  padding: 0 0.5em 0.3625em 0.5em;
  margin-bottom: 0.3em;

  a {
    font-size: $breadcrumbs-item-font-size;
    font-weight: $global-weight-bold;
    display: block;

    &.prev{
      float: left;
    }

    &.next{
      float: right;
    }
  }

  @include breakpoint(small only) {
    margin-left: rem-calc(map-get($grid-column-gutter,small))*-0.5;
    margin-right: rem-calc(map-get($grid-column-gutter,small))*-0.5;
    padding: 0 0.5em 0.3625em 0.5em;
    a {
      font-weight: $global-weight-normal;
      font-size: rem-calc(16);
      line-height: 3;
    }
  }
}

@include breakpoint(small retina) {
  .breadcrumbs li:nth-last-child(2) {
    background-image: url('/images/chevron@2x.png');
    background-size: 13px 22px;
  }
}

@include breakpoint(retina) {
  .menu-desktop-bar {
    .menu {
      > li {
        &.home-menuitem > a {
          background-image: url('../images/Home%20icon@2x.png');
        }
      }
    }
  }
}
