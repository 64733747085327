.bespoke-example-image,
.bespoke-instructions-image {
  background-color: #FFF;
  text-align: center;
}

.callout.how-to-order
{
  color: #000;

  div, ul, p
  {
    color: #000;
  }
}