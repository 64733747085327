.top-bar-container-row {
  background-color: get-color(primary);
}

.top-bar {
  padding: rem-calc(23 0 14 0);
  @include breakpoint(small only) {
    padding: rem-calc(10) 0 0;
  }

  @include top-bar-unstack;

  ul.menu {
    &.logo-menu, &.cart-search  {
      display: flex;
      list-style: none;
      li {
        display: block;
      }
    }
  }

  .cart-search {
    overflow: visible;
  }

  a {
    color: $white;
    background-color: transparent;
  }

  .top-bar-left {
    .header-main-search-container
    {
      //required because phone number is not always present
      height: rem-calc(58px);
      width: rem-calc(325px);
      position: relative;
      top: rem-calc(-7);
    }

    .logo {
      margin-right: 1rem;
      width: 135px;
      box-sizing: content-box;
  
      > h1
      {
        margin: 0;
        display: block;
        padding: 0;
        a {
          display: block;
          background: url('../images/WRP%20logo@2x.png') no-repeat;
          background-size: contain;
          width: 135px;
          height: 47px;
          color: transparent;
          overflow: hidden;
          padding: 0;
  
          @include breakpoint(small only) {
            width: 87px;
            height: 30px;
            background-size: contain;
          }
        }
      }
    }


    label {
      color: $white;
      font-weight: $global-weight-bold;
      font-size: rem-calc(14);
      line-height: 1.5;
    }
    input {
      margin-right: 0;
      font-weight: $global-weight-normal;
    }
    .input-group {
      margin-bottom: 0;
      &.no-phone {
        padding-top: rem-calc(15px);
      }
    }
    input.search-button{
      // Forces button to be the same height as the adjoined search text field
      @extend .input-group-field;
    }

    input.search-button,
    input.search-button:hover {
      background-image: url('../images/Search%20icon%20small@2x.png');
      background-size: 19px 19px;
      background-repeat:  no-repeat;
      background-position: center;
      width: rem-calc(40);
      height: rem-calc(35);
    }

    input#searchInput,
    input#searchInputMobile{
      border-radius: 0;
      appearance: none;
    }

  }


  .top-bar-right {
    display: flex;
    > .menu {
      display: flex;
    }
    .menu.is-dropdown-submenu {
      background-color: $white;
    }    
  }



  .badge {
    position: absolute;
    right: -0.5rem;
    top: 0;
  }



  .nav-menu-icon {
    @include breakpoint(small only) {      
      background-image: url('../images/Menu%20icon@2x.png');
      background-size: 28px 24px;
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      color: transparent;
      cursor: pointer;
    }
  }


  .menu
  {
    &.session-menu
    {
      // li.is-dropdown-submenu-parent.opens-left {
      li {
        > a {
          color: $white;
          background-color: transparent;
        }

        .submenu
        {
          left: auto;
          right: 0;
        }
      }
      .account-menu-link
      {
        position: relative;
      }
      span.trade-account, span.staff-account {
        position: absolute;
        right: rem-calc(4px);
        top: rem-calc(-10px);
        vertical-align: super;
        font-size: rem-calc(13px);
        font-weight: normal;
        color: $specials-color;
        i.fi-star, i.fi-torso
        {
          display: inline-block;
          margin-right: 3px;
          position: relative;
          top: -1px;
        }
      }

      span.staff-account {
        color: $light-gray;
      }
    }

    &.is-dropdown-submenu
    {
      > li > a
      {
        font-weight: normal;
        color: $primary-shade-3;
        &:hover,
        &:focus {
          color: $primary-shade-1 !important;
        }
      }
    }
  }

  .menu>li:not(.menu-text) {
    >a {
      position: relative;

      &:hover,
      &:focus {
        color: _transparentize($white, 0.3);
      }
    }

    &.logo>h1 {
      a {
        padding: 0 1rem !important;

        &:hover,
        &:focus {
          color: transparent;
        }

        // @include breakpoint(small only) {
        //   pointer-events: none;
        // }
      }
    }

  }

  .nav-cart-icon {
    overflow: visible;
  }

  .nav-cart-icon > a {
    padding-left: rem-calc(40) !important;
    background: url('../images/Cart%20icon@2x.png') no-repeat left;
    background-size: 33px 30px;
    overflow: visible;
    @include breakpoint(small only) {
      background-position: center;
      width: 40px;
      height: 40px;
      color: transparent;
      &:hover, &:focus {
        color: transparent !important;
      }
    }
  }
  .nav-search-icon > a {
    @include breakpoint(small only) {
      background: url('../images/Search%20icon@2x.png') no-repeat center;
      background-size: 29px 29px;
      width: 40px;
      height: 40px;
      color: transparent;    
      &:hover,
      &:focus {
        color: transparent !important;
      }
    }
  }




} // end .top-bar





.header-window {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: get-color(primary);
  h1 {
    display: none;
  }
}

@include breakpoint(large) {
  #searchInput {
    min-width: 286px;
  }
}

@media screen and #{breakpoint(medium only)} and #{breakpoint(850px down)} {
  .top-bar-left {
    padding-bottom: rem-calc(12);
  }
}

@include breakpoint(medium) {
  .top-bar-right .menu {
    margin-top:rem-calc(12);
  }
}

@media #{$small-only} {

  .top-bar {
    padding-top: rem-calc(15);

    .top-bar-left {
      .nav-menu-icon {
        margin: rem-calc(3px 2px 0 5px);
      }

      .logo {
        margin: rem-calc(0 0 14px 0);
      }
    }

    .top-bar-right {
      position: relative;
      top: rem-calc(-7px);
      padding-right: rem-calc(4px);
    }

    .margin-top-large.homepage {
      margin-top: rem-calc(60px);

      &.is-hero-margin {
        margin-top: rem-calc(15px);
      }
    }
  }

  input.search-button,
  input.search-button:hover {
    background-image: url('../images/Search%20icon%20small@2x.png');
    background-size: 19px 19px;
    background-repeat:  no-repeat;
    background-position: center;
    width: rem-calc(40);
    height: rem-calc(35);
  }

}