.help-text.product-special-run-info, .help-text.end-of-line-stock
{
  font-weight: bold;
  a
  {
    font-weight: normal;
    .fi-info
    {
      font-size: 18px;
      line-height: 13px;
      position: relative;
      top: 2px;
    }
  }

  &.end-of-line-stock
  {
    color: get-color(alert);
    .fi-alert
    {
      font-size: 24px;
      line-height: 13px;
      position: relative;
      top: 2px;
    }

    a.tip-top
    {
      color: get-color(alert);
      text-decoration: underline;
    }
  }

}

.product-properties
{
  dt .hint
  {
    display: inline-block;
    font-size: rem-calc(14px);
    font-weight: normal;
    margin-left: rem-calc(15px);

    .fi-info
    {
      font-size: 18px;
      line-height: 13px;
      position: relative;
      top: 2px;
    }
  }



  .bulk-savings-heading.trade {
    color: $support-green
  }

  
  /* Bulk savings Accordian */
  .bulk-savings-container {
    display: block;
    margin-bottom: 30px;

    .hint-text {
      font-size: rem-calc(12px);
    }
    
    /**
     * @NOTE override defaults from components/_accordion-js.scss
     */

    .accordionjs {
      padding: 10px;
      background-color: rgba($white, 0.5);  
    }

    .acc_section, .acc_head, .acc_content, .acc_section.acc_active > .acc_head {
      border: none;
      background-color: transparent;
      padding: 0;
    }

    .acc_content .grid-x.secondary {
      color: get-color(secondary);

      .fi-info {
        display: inline-block;
        margin-left: rem-calc(5px);
        position: relative;
        top: rem-calc(1px);
        &:hover {
          text-decoration: none;
        }
      }
    }

    .accordionjs .acc_section .acc_head {
      @include link_standard;
      font-weight: bold;
      line-height: 1;
      margin-bottom: rem-calc(10px);
      transition: margin 250ms;
    }

    // single material - hack the accordion element so it's not clickable
    .accordionjs.single-material {
      pointer-events: none;
      .acc_section .acc_head {
        color: $black;
      }    
    }

    .accordionjs .acc_content {
      margin-bottom: rem-calc(15px);
    }

    .accordionjs .acc_section:last-child {
      &:not(.acc_active) {
        .acc_head {
          margin-bottom: 0;
        }
      }      
      .acc_content {
        margin-bottom: 0;
      }
    }

    .accordionjs:not(.single-material) .acc_section:not(.acc_active) {
      position: relative;
      ::before {
        content: '+';
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -0.5rem;
      }
    }

  }

}

.product-links
{
  margin-bottom: 25px;
}

.specials-text {
  color: $specials-color-dark;
}


.product-page .special-offer-details
{
  border-radius: rem-calc(2px);
  padding: rem-calc(5px 10px);
  background-color: $specials-color-dark;
  color: #222;
  font-size: rem-calc(14px);
  margin-bottom: rem-calc(13px);
}

.product-page
{
  .replacement-product-panel {
    
    .image-link, .image-link img.thumbnail
    {
      display: block;
      margin: 0;
    }

    h1 a {
      font-weight: normal;
    }
  }
}

.scroll-to-lengths-button
{
  display: table;
  margin-top: rem-calc(7px);
}

.simple-bespoke-cart-buttons
{
  margin-top: rem-calc(15px);

  .button
  {
    margin-bottom: 0;
  }
}

.matching-item-thumbs
{
  .button.profile-group-button, .switch
  {
    display: inline-block;
  }
}


.moulding
{
  .product-link,  .sample-added-info
  {
    font-size: rem-calc(13px);
    line-height: rem-calc(16px) !important;
    display: table;

    &::before
    {
      content: " ";
      background-repeat: no-repeat;
      background-size: 14px 19px;
      display: inline-block;
      height: 20px;
      margin-right: 5px;
      position: relative;
      top: 3px;
      width: 15px;
    }

    &.cad::before
    {
      background-image: url("/images/dwg-icon-tiny.png");
    }

    &.pdf::before
    {
      background-image: url("/images/pdf-icon-tiny.png");
    }

    &.video::before
    {
      background-image: url("/images/video-icon-tiny.png");
    }

    &.add-sample-to-cart
    {
      display: inline-block; 
    }
    &.add-sample-to-cart::before
    {
      background-image:  url("/images/sample-icon-tiny.png");
    }
  }

  .samples-info
  {
    font-size: rem-calc(18px);
    line-height: rem-calc(18px) !important;
    display: inline-block;
    position: relative;
    top: rem-calc(2px);
  }

  .check-in-stock-hint
  {
    margin-top: rem-calc(-13px);
  }

  .sample-added-info
  {
    display: inline-block;
    &::before
    {
      background-image:  url("/images/sample-icon-tiny.png");
    }

  }

  .remove-sample-from-cart
  {
    display: inline-block;
    font-size: rem-calc(13px);
    line-height: rem-calc(16px) !important;
    margin-left: 15px;
  }

  em.highlighted
  {
    font-weight: normal;
    color: get-color(primary);
    font-size: rem-calc(14px);
  }
}
