.off-canvas-wrapper {
  background-color: transparent;
  .off-canvas {
    background-color: $primary-shade-3;
  }
}

.menu-mobile {

  background-color: $primary-shade-3;

  .drilldown .is-drilldown-submenu {
    background-color: transparent;
  }

  ul>li {
    &.home-menuitem {

      // width: rem-calc(57);

      >a {
        background-image: url('../images/Home%20icon.png');
        background-size: 23px 22px;
        background-repeat: no-repeat;
        background-position: rem-calc(17px 19px);
        color: transparent;
        // width: rem-calc(21px);
        padding: rem-calc(16px);
        overflow: hidden;
        box-sizing: content-box;
      }

    }

    &.mouldings-in-stock-menuitem>a,
    &.profile-shapes-menuitem>a {
      background-color: _transparentize(get-color(success), 0.9);
      color: get-color(success);
    }

    &.mouldings-specials-menuitem>a {
      background-color: _transparentize(get-color(success), 0.9);
      color: get-color(warning);
    }

    &.special-run-menuitem {
      display: none;
    }

  }


  &.show-special-run {
    ul>li.special-run-menuitem {
      display: block;
    }
  }
}
