table {
  width: 100%;
  font-size: 0.875rem;
}

.product-material-stock-levels {
  width: auto;
  thead, tbody, tfoot {
    background-color: transparent;
  }
  th {
    text-align: left;
  }
  tbody th, tbody td {
    padding: 0 0;
  }
  tbody th {
    padding-right: 0.5rem;
  }
}

.product-pricing-structure {
  width: auto;
  thead, tbody, tfoot {
    border: none;
  }
  //border: 1px solid red;
  //border-spacing: 1px;
  //border-collapse: separate;
  > tbody > tr {

    &.highlighted > td {
      color: $body-font-color;
      &:last-child {
        border: none;
      }
      span.marker {
        font-weight: normal;
        color: get-color(success);
      }
    }
    > td {
      border: 1px solid get-color(secondary);
      &:last-child {
        border: none;
      }
    }

    td.marker-cell
    {
      background: #fff;
    }

  }
}

.product-lengths-in-stock{
  font-size: 1rem;
  border: none;
  tbody {
    th, td {
      padding: 0.2rem 0.5rem;
    }
  }
  thead, tbody, tfoot {
    border: none;
    tr {
      background-color: transparent;
      td{
        vertical-align: top;
        &:first-child {
          padding-left: 0;
          width: 3rem;
        }
        &:last-child {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

}

.sidebar-cart, .pricer-table, .unstriped {
  tbody tr:nth-child(even) {
    background-color: transparent;
  }
}
