.mouldings.special_run {
  .off-canvas-content {
    // opacity: 0.5;
    // background-color: get-color(secondary);
    background: repeating-linear-gradient(-45deg, $trade_stripe_light, $trade_stripe_light 10px, $trade_stripe_dark 10px, $trade_stripe_dark 20px);;

    .section-heading {
      color: #333;
    }
  }

  .product-grid
  {
    position: relative;

    &.blur 
    {
      > :not(.special-run-intro-panel){
        filter: blur(4px);
        opacity: 0.5;
      }
      
    }

    .special-run-intro-panel .callout
    {
      // position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.8);
      z-index: 100;

      .fi-info {
        font-size: 18px;
        position: relative;
        top: 2px;
      }
    }
  }

  .product-grid-item {
    // h6.special-run {
    //   font-weight: bold;
    //   font-size: rem-calc(14px);
    //   margin: rem-calc(7px 0 2px 0);
    // }

    p.help-text
    {
      margin: 0;
      font-size: rem-calc(13px);
    } 

    

 }
}
