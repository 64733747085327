@use 'sass:color';

.callout.order-payment-panel,
.checkout,.request-changes-panel {
  textarea.manual-field {
    box-sizing: border-box;

    padding: 10px 12px;
    margin-top: 6px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;

    box-shadow: 0 1px 3px 0 #e6ebf1;

    &::placeholder {
      color: #777;
    }
  }

  p.bacs-hint {
    margin: rem-calc(10px 0 20px 0);

    .fi-info {
      font-size: rem-calc(18px);
      position: relative;
      // top: 2px;
      left: 1px;
      color: get-color(primary);
      margin: 0 3px 0 0;
      vertical-align: bottom;
    }
  }

  .payment-card,
  .payment-bacs {
    .hint-text.danger {
      color: get-color(alert);
    }

    h3.payment-type-heading {
      margin: rem-calc(20px 0 0 0);
    }


    .payment-form-container {

      .instruction-list {
        margin-top: rem-calc(20px);
      }

      form {
        width: 100%;
      }

      label {
        font-size: rem-calc(14px);
        font-weight: bold;
        color: $medium-dark-gray;
        margin-top: rem-calc(15px);
      }

      .manual-field {
        width: 100%;
        max-width: none;
      }

      fieldset:disabled .manual-field {
        color: #CCC;
      }

      .card-errors {
        margin-top: 5px;
        &.bacs-errors {
          display: none;
        }
      }


      /**
      * The CSS shown here will not be introduced in the Quickstart guide, but shows
      * how you can use CSS to style your Element's container.
      */
      .StripeElement,
      .manual-field {
        box-sizing: border-box;

        height: 40px;

        padding: 10px 12px;

        border: 1px solid transparent;
        border-radius: 4px;
        background-color: white;

        box-shadow: 0 1px 3px 0 #e6ebf1;
        -webkit-transition: box-shadow 150ms ease;
        transition: box-shadow 150ms ease;

        &::placeholder {
          color: #777;
        }
      }

      .StripeElement--focus {
        box-shadow: 0 1px 3px 0 #cfd7df;
      }

      .StripeElement--invalid {
        border-color: #fa755a;
      }

      .StripeElement--webkit-autofill {
        background-color: #fefde5 !important;
      }

      input {
        margin-bottom: 5px;

        &.postcode-textfield {
          margin-bottom: 15px;
        }

        &.bacs-info:disabled {
          background: $white;
          border-style: hidden;
          box-shadow: none;
        }
      }

      #address_zip {
        text-transform: uppercase;
      }

      input.month-mm,
      input.year-yyyy {
        display: inline-block;
      }

      .cc_form_buttons {
        display: inline-block;
        margin: rem-calc(20px 18px 0 0);

        &.edit-order-button {
          margin-right: 0;
        }
      }

      .terms-and-returns {
        margin-top: 25px;

        p {
          margin-bottom: 3px;
        }

        .terms-and-returns-checkboxes {
          margin-left: 10px;

          label {
            color: black;
            font-weight: normal;
            margin-top: 6px;
          }
        }
      }
    }
  }
}


$background-secondary: #f0f0f0;
$background-secondary-hover: color.scale(get-color(secondary), $lightness: 70%);

$background-alert: color.scale(get-color(alert), $lightness: 90%);
$background-alert-hover: color.scale(get-color(alert), $lightness: 70%);



.accordion.payment-methods {
  &.secondary {
    background-color: $background-secondary;
    .accordion-item {
      background-color: $background-secondary;
      .accordion-title {
        background-color: transparent;
        color: #222;

        &:hover {
          background-color: $background-secondary-hover;
          text-decoration: none;
          color: #000;
        }

        .icons {
          font-size: rem-calc(22px);
          color: get-color(primary);
          position: relative;
          top: 2px;
          display: inline-block;
          margin-right: rem-calc(10px);

          &.new-card {
            color: get-color(success);
          }
        }
      }

      &.is-active {
        .accordion-title {
          background-color: $background-secondary-hover;
          text-decoration: none;
          color: #000;
        }
      }

      &.disabled-error {
        opacity: 0.4;
        .accordion-title {
          pointer-events: none;
        }
        .accordion-content {
          display: none !important;
        }
      }
      
    }
  }

  &.alert {
    background-color: $background-alert;
    .accordion-item {
      background-color: $background-alert;
      .accordion-title {
        background-color: transparent;       

        &:hover {
          background-color: $background-alert-hover;
        }  
      }

      &.is-active {
        .accordion-title {
          background-color: $background-alert-hover;
        }
      }
      
      .accordion-content {
        background-color: transparent;

      }
    }
  }


  .accordion-content {
    &.saved-cards-content {
      .card-errors {
        margin-bottom: rem-calc(30px);
      }
    }

    &.credit-card-content {
      .form-row > label:first-child {
        margin-top: 0;        
      }
    }
    &.paypal-content {
      margin-top: rem-calc(15px);
    }
    &.bacs-content {
      margin-top: rem-calc(10px);
    }


    background-color: transparent;

    .payment-form-container
    {
      label.heading-label {
        padding-left: 1px;
      }
    }

    // note that SCSS component _saved_cards_list is used on this page

  }




  .bacs-button
  {
    margin: 30px 0 0 0;
  }
}