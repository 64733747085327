@mixin icon-base($image-url, $w, $h, $valign) {
  display: inline-block;
  $url:"#{$image-url}";
  background-image: url($url);
  background-repeat: no-repeat;
  background-position:  center;
  vertical-align: $valign;
  width: $w;
  height: $h
}

.dispatch-time-icon {
  @include icon-base("/images/Delivery%20time%20icon.png", 16px, 16px, text-top);
}

.in-stock-tick-icon {
  @include icon-base("/images/In%20stock%20icon.png", 17px, 17px, text-top);
}

.in-stock-cross-icon {
  @include icon-base("/images/Not%20in%20stock%20icon.png", 17px, 17px, text-top);
}

.added-to-cart-tick-icon {
  @include icon-base("/images/tick.png", 25px, 20px, top);
}

.info-icon {
  @include icon-base("/images/Info%20icon.png", 17px, 17px, text-top);
}

.delete-icon {
  @include icon-base("/images/Delete%20icon.png", 12px, 13px, text-top);
}

.in-stock-icon {
  @include icon-base("/images/stock%20in%20stock%20icon.png", 49px, 20px, text-top);
}

.out-of-stock-icon {
  @include icon-base("/images/stock%20out%20of%20stock%20icon.png", 26px, 28px, text-top);
}

// @ see _pricer.scss
// .machined-icon-blue {
//   @include icon-base("/images/sprite-cutter-256.png", 26px, 26px, middle);
//   background-size: cover;
// }

span.has-tip, a.has-tip
{
  border-bottom: none;
}


.plus-icon {

  &:before, &:after{
    content: '';
    pointer-events: none;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    box-shadow: inset 0 0 0 32px;
  }

  &:before{
    width: 16px;
    height: 2px;
  }

  &:after{
    width: 2px;
    height: 16px;
  }

}

.fi-info
{
  &.primary
  {
    color: get-color(primary);
  }
  &.secondary
  {
    color: get-color(secondary);
  }
}

.minus-icon{

  &:before{
    content: '';
    pointer-events: none;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    box-shadow: inset 0 0 0 32px;
  }

  &:before{
    width: 16px;
    height: 2px;
  }

}



@include breakpoint(retina) {
  .dispatch-time-icon {
    background-image: url("/images/Delivery%20time%20icon@2x.png");
    background-size: 16px 16px;
  }
  .in-stock-tick-icon {
    background-image: url("/images/In%20stock%20icon.png");
    background-size: 17px 17px;
  }
  .in-stock-cross-icon {
    background-image: url("/images/Not%20in%20stock%20icon.png");
    background-size: 17px 17px;
  }
  .added-to-cart-tick-icon {
    background-image: url("/images/tick@2x.png");
    background-size: 25px 20px;
  }
  .info-icon {
    background-image: url("/images/Info%20icon@2x.png");
    background-size: 17px 17px;
  }
  .delete-icon {
    background-image: url("/images/Delete%20icon@2x.png");
    background-size: 12px 13px;
  }
  .in-stock-icon {
    background-image: url("/images/stock%20in%20stock%20icon@2x.png");
    background-size: 49px 20px;
  }
  .out-of-stock-icon {
    background-image: url("/images/stock%20out%20of%20stock%20icon@2x.png");
    background-size: 26px 28px;
  }
}
