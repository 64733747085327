.badge {
  font-weight: $global-weight-bold;
  line-height: 1.5 !important;
  color: $white;
  &.warning {
    color: $black;
  }
  &.alert {
    color: $white;
  }
}
