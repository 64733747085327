.callout-header-dark {
  background-color:  $primary-shade-2;
  margin: -1rem -1rem 1rem !important;
  padding: 1rem;
  color: white;
  > a {
    color: white;
    font-weight: normal;
  }
}

.homepage-panel {
  @include callout-base;
  background-color: $white;
  // box-shadow: 2px 3px 2px 0px $light-gray;
  border: none;
  padding: 0;
  margin-bottom: 15px;
  p,h1,h2,h3,h4,h5 {
    margin: 0;
    padding: rem-calc(10);
  }
  img {
    display: block;
  }
  &.large-panel,
  &.large-panel2 {
    p.lead {
      font-weight: $global-weight-normal;
      padding-bottom: 0;
    }
    p+p {
      padding-top: 0;
    }
    > a {
      &:hover,
      &:focus {
        text-decoration: none;
        p:not(.lead) {
          text-decoration: underline;
        }
      }
    }
  }
  &.large-panel {
    background-color: $primary-shade-1;
    a {
      color: $white;
    }
  }
  &.large-panel2,
  &.small-panel {
    background-color: $white;
  }
  &.small-panel {
    a {
      color: $black;
      font-weight: $global-weight-normal;
    }
  }

  &.about-video
  {
    color: $white;
    background-color: get-color(primary);

    p a
    {
      color: $white;
    }
  }

}


.mobile-search-panel{
  @include callout-base;
  background-color: #F0F0F0;
  padding: 1rem;
  border-radius: 0;
  margin-bottom: 0;
}

.matching-items-panel{
  @include callout-base;
  background-color: $white;
  padding: 1rem;

  & > .row
  {
    margin: 0; // overrides the margin of -10 px used for the matching items (same profile / set) outside the panel
  }
}

.matching-items-banner{
  @include callout-base;
  background-color: _transparentize($white, 0.5);
  padding: 0.5rem;
  > p {
    font-size: rem-calc(14);
  }
}
.buy-panel-wrapper {
  background-color: $white;
  overflow: hidden;
  margin: 0 0 1rem 0;
  position: relative;
}

.buy-panel {    
  @include callout-base;
  background-color: $white;
  z-index: 1;

  &.lengths-pricer
  {
    margin: 0;
  }

  .min-quantity-advice
  {
    font-weight: bold;
  }
  .min-quantity-hint, .lengths-hint, .category-hint
  {
    font-weight: normal;
    .fi-info
    {
      font-size: 18px;
      line-height: 13px;
      position: relative;
      top: 2px;
    }
  }
  .min-quantity-hint {
    display: block;
  }

  .lengths-and-minimum-info
  { 
      display: flex;
      justify-content: space-between;
  
    .lengths-prompt
    {
      white-space: nowrap;
    }
    .lengths-mode-minimum-order
    {
      text-align: right;
      margin: rem-calc(6px 0 0 0);
    }
  }

  .need-more-container
  {
    margin-top: rem-calc(26px);

    .quantityField
    {
      display: inline-block;
    }

    .need-more-form-container
    {
      margin-top: rem-calc(10px);
    }

    .submit-need-more-button
    {
      vertical-align: top;
      margin: rem-calc(1px 0 10px 10px);
    }
  }
  
}

.added-to-cart {
  position: relative;
  z-index: 0;
  padding: 1rem;
  margin: -5.4rem 0 1rem 0;
  transition: margin 1s;

  background-color: get-color(primary);
  color: $white;
  min-height: 60px;

  &.product-added {
    margin-top: -1rem;
  }

  p {
    white-space: nowrap;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @extend .alt-link-colours;

  .button
  {
    margin: 0;
  }
}

.admin-panel {
  text-align: right;

  &.edit-product,&.edit-videos
  {
    text-align: center;

    a.button.tiny
    {
      padding-left: 30px;
      padding-right: 30px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .specials-help-text {
    display: table;
    margin: 0 auto rem-calc(15px) auto;
    border-radius: rem-calc(2px);
    padding: rem-calc(5px 10px);
    background-color: $specials-color-dark;
    color: #222;
    font-size: rem-calc(14px);
  }
}

.in-stock-info-panel {
  @include callout-base;
  // background-color: _transparentize(get-color(success), 0.85);
  background-color: $white;
  p {
    font-size: rem-calc(14);

    &.bold
    {
      margin-bottom: 0;
    }

    span, .button.small
    {
      display: inline-block;
      padding-right: 10px;
      margin-bottom: rem-calc(16px);
      vertical-align: middle;
    }

    .button.small
    {
      position: static;
    }

    .button.last-button {
      margin-bottom: 0;
    }
  }

}

.info-panel {
  @include callout-base;
  background-color: _transparentize(get-color(primary), 0.85);
  p {
    font-size: rem-calc(14);
  }
}

.alert-panel {
  @include callout-base;
  text-align: center;
  background-color: _transparentize(get-color(alert), 0.85);
  p {
    font-size: rem-calc(14);
  }
}

.stock-panel {
  .saved-hint
  {
    color: get-color(primary);
    display: none;
    line-height: rem-calc(14px);
    margin-left: rem-calc(10px);
    white-space: nowrap;
  }

  input.text:disabled
  {
    @include read_only_panel;
  }

  .stock-panel-top {
    @include callout-base;
    margin-bottom: 0.5rem;
    background-color: $white;
    display: table;
    width: 100%;
    .stock-panel-top-block {
      display: table-cell;
      vertical-align: middle;
      &:last-child {
        > div {
          float: right;
        }
      }
      > div {
        display: table;
        > * {
          display: table-cell;
          vertical-align: middle;
        }
        > label {
          display: inline-block;
          font-size: rem-calc(14px);
          padding-right: 0;
        }
        input.text {
          text-transform: uppercase;
          display: inline-block !important;
          margin-left: rem-calc(10px);
          padding-right: 0;
        }
        > .button
        {
          display: inline-block;
          margin-right: rem-calc(1px);
          &:first-child
          {
            margin-right: rem-calc(16px);
          }
        }
        .saved-hint
        {
          display: none;
        }
      }

      &.add-mode-active
      {
        .button
        {
          visibility: hidden;
          pointer-events: none;
        }
        label
        {
          opacity: 0.5;
        }
      }

    }
  }
  .stock-panel-body {
    margin: $callout-margin;
    .columns {
      &:first-child {
        padding-right: 0;
      }
      &:last-child {
        padding-left: 0;
      }
    }

    .pricer-run-quantity-warning
    {
      margin-top: rem-calc(10px);
    }
    .minimums-container
    {
      min-width: rem-calc(300px);

      &.single-items-mode {
        margin-top: rem-calc(15px);
      }
    }
    .save-stock-settings
    {
      min-width: rem-calc(104px);
      margin: rem-calc(33.5px) rem-calc(33.5px) 0 rem-calc(10px);
      @include breakpoint(small only){
        margin:rem-calc(10px) 0 rem-calc(10px) rem-calc(10px);
      }
    }
    .material-minimums-container
    {
      font-size: 0; // remove effect of spaces due to inline blocks
      margin-top: rem-calc(10px);
      &.pricer-run-quantity-container
      {
        margin-top: rem-calc(-10px);
        input.text.quantityField.numeric.pricer-run-quantity.invalid
        {
          background-color: get-color(warning) !important;
        }
      }
      
      span, label, input
      {
        display: inline-block;
      }
      span
      {
        position: relative;
        top: rem-calc(-0.5px);
      }
      .label-text
      {
        display: inline-block;
        min-width: rem-calc(190px);
        margin-left: rem-calc(10px);
      }
      input
      {
        width: 8ex;
      }
    }

  }
  .stock-timber-panel-header {
    margin-bottom: 1rem;
    @include breakpoint(medium) {
      .hint-text
      {
        margin-left: 2.3rem;
      }
    }
  }

  .tabs-panel
  {
    .add-lengths-mode-info, .add-lengths-cell, .add-lengths-save-button, .cancel-add-lengths-button
    {
      display: none;
    }

    &.add-lengths-mode
    {
      .stock-input-cell, .material-minimums-container, .taken-cell, .save-material-stock-button, .add-lengths-button, .load-history
      {
        display: none;
      }

      .add-lengths-save-button, .cancel-add-lengths-button
      {
        display: inline-block;
      }

      .add-lengths-cell
      {
        display: table-cell;
        color: get-color(success);

        i
        {
          display: inline-block;
          margin-right: rem-calc(4px);
        }
      }

      .text.add-lengths-field:enabled
      {
        display: inline-block;
        border: 1px solid get-color(success);

        &.edited
        {
          background-color: _lighten(get-color(success), 40%);
        }
      }


      .add-lengths-mode-info
      {
        display: block;
        margin: rem-calc(-9px 0 0 1px);
        color: get-color(success);
        line-height: rem-calc(32px);
        span
        {
          position: relative;
          top: rem-calc(0);
        }
      }
    }
  }

  button {
    margin : 0;
    &.add-lengths-button, &.add-lengths-save-button
    {
      margin-right: rem-calc(16px);
    }
  }
  input.quantityField {
    margin: 0;
    max-width: 12ex;
  }
  .tabs {
    .badge {
      margin-top: -0.3em
    }
    .tabs-title
    {
      &.disabled
      {
        opacity: 0.5;
        pointer-events: none;
        cursor: default;

        a
        {
          pointer-events: none;
        }
      }

      &.add-mode-active, &.disabled
      {
        .badge.stock-tab-badge-estimated-warning {
          display: none;
        }
      }

      a
      {
        text-decoration: none;
      }
      .help-text
      {
        display: block;
        font-weight: normal;
        margin: rem-calc(6px  0 -1px 0);
        &.hidden
        {
          display: none;
        }
      }
    }

  }
  table {
    th, td {
      vertical-align: top;
      font-size: .8125rem;
      text-align: left;
      label {
        font-size: .8125rem;
        margin-top: 0.3em;
      }
    }
    input {
      max-width: 8ex;
      margin-bottom: rem-calc(5px);
      &.updated
      {
        background-color: rgba(0, 255, 32, 0.3);
      }
      &.takenvalue
      {
        &.zero-taken {
          pointer-events: none;
        }
        &.over-allocated {
          color: get-color(alert);
          pointer-events: none;
        }
        &:disabled, &:read-only {
          background: transparent;
          border: none;
          box-shadow: none;
          pointer-events: none;
          &.clickable {
            color: get-color(primary) !important;
            -webkit-text-fill-color: unset;
            text-decoration: underline;
            pointer-events: auto;
            cursor: pointer;
          }
        }
      }
    }
  }

  .hint {
    display: none;
    color: get-color(primary);
  }

}
.sidebar-table-panel {
  @include callout-base;
  background-color: $white;

  &.sidebar-panel {
      .expanded-date-col {
        display: none;
      }
    }

  &.stock-view-page {
    .sidebar-toggle-button {
      display: none;
    }
    table.product-current-orders-table {
      margin-bottom: 0;
    }
    .show-more-button {
      margin-bottom: 0;
    }
  }

  table {
    th {
      white-space: nowrap;
    }

    th,
    td {
      vertical-align: top;
      font-size: .8125rem;
      text-align: left;

      label {
        font-size: .8125rem;
        margin-top: 0.3em;
      }

      .help-text {
        font-size: rem-calc(12px);
        white-space: nowrap;

        &.approved {
          color: get-color(success);
        }

        &.not-approved {
          color: get-color(alert);
        }

      }

      a {
        white-space: nowrap;
      }
    }
  }

  ul {
    margin-bottom: 0;
  }

  .go-to-production-btn {
    margin-top: rem-calc(15px);
  }
}

.callout-header-image {
  display: block;
  margin-left: -$global-padding;
  margin-right: -$global-padding;
  margin-top: -$global-padding !important;
  margin-bottom: $global-padding;
  height: 160px;
  background-color: $light-gray;
}



body.bespoke .callout-header-image {
  background: url('/images/bespoke-mouldings-header.jpg') no-repeat center;
  background-size: cover;
}

body.timber-types .callout-header-image
{
  background: url('/images/timber-types-header.jpg') no-repeat center;
  background-size: cover;
}

body.careers .callout-header-image {
  background: url('/images/wrp-careers-header.jpg') no-repeat center;
  background-size: cover;

  &.marketing-consultant
  {
    background: url('/images/wrp-careers-marketing-header.jpg') no-repeat center;
    background-size: cover;
  }

  &.sales-and-office-administrator
  {
    background: url('/images/wrp-careers-sales-and-office-administrator.jpg') no-repeat center;
    background-size: cover;
  }
}

body.cad-files .callout-header-image
{
  background: url("/images/wrp-cad-files-header.jpg") no-repeat center;
  background-size: cover;
}

body.help-centre .callout-header-image
{
  background: url("/images/wrp-help-centre-header.jpg") no-repeat center;
  background-size: cover;
}


body.help-centre .help-centre-samples .callout-header-image
{
  background: url("/images/wrp-help-centre-samples-header.jpg") no-repeat center;
  background-size: cover;
  background-position: bottom center;
}


body.account .trade-info .callout-header-image
{
  background: url("/images/wrp-trade-header@2x.jpg") no-repeat center;
  background-size: cover;
  background-position: bottom center;
}



@include breakpoint(large) {
  .homepage-panel {
    &.large-panel,
    &.large-panel2 {
      .caption {
        padding: rem-calc(9) 0;
      }
    }
  }
  .callout-header-image {
    height: 240px;

    &.careers-page
    {
      height: 298px;
    }
  }
}

@include breakpoint(small only) {
  .homepage-panel{
    &.small-panel img
    {
      max-width: 100%;
    }
    &.small-no-image {
      &.small-panel {
        margin-bottom: 0;
        img {
          display: none;
        }
      }
    }
  }
  .added-to-cart {
    padding-top: 18px;
    p {
      font-size: rem-calc(14px);
    }
  }
}


@include breakpoint(medium only){

  .stock-panel {
    .stock-panel-body {

      .lengths
      {
        th, td
        {
          padding-left: 0;
          padding-right: rem-calc(2px);
        }
      }

      input.text:disabled
      {
        width: 5ex;
        padding: 0;
        text-overflow: ellipsis;
      }
    }
  }
}
