@use 'sass:color';

.margin-top-large {
  margin-top: 2rem;
}
.margin-bottom-large {
  margin-bottom: 2rem;
}
.margin-top-small {
  margin-top: 1rem;
}
.margin-bottom-small {
  margin-bottom: 1rem;
}
.margin-bottom-none{
  margin-bottom: 0;
}
.margin-topbottom-large {
  @extend .margin-top-large;
  @extend .margin-bottom-large;
}
.margin-topbottom-small {
  @extend .margin-top-small;
  @extend .margin-bottom-small;
}

.highlighted {
  color: get-color(success);
  font-weight: $global-weight-bold;
}

.bold {
  font-weight: $global-weight-bold;
}

// @NOTE deprecated - use .hide, which is provided by Foundation
.hidden {
  display:none;
}

.visibly-hidden
{
  visibility: hidden;
}

.danger {
  color: get-color(alert);

  &:hover, &:active, &:focus
  {
    color: _darken(get-color(alert), 10%);
  }
}

.success {
  color: $admin-success-green;

  &:hover, &:active, &:focus
  {
    color: _darken($admin-success-green, 10%);
  }
}

.callout {
  &.success, &.danger {
    &:hover, &:active, &:focus
    {
      color: inherit;
    }
  }
}

.warning {
  color: get-color(warning);

  &:hover, &:active, &:focus
  {
    color: get-color(warning);
  }

  >p
  {
    color: $medium-dark-gray; // we don't want yellow text on a yellow background
  }
}

.alt-link-colours {
  a:not(.button) {
    color: get-color(success);
    &:hover {
      color: color.scale(get-color(success), $lightness: -14%);
    }
  }
}

.inline-block {
  display: inline-block;
  @include breakpoint(small only) {
    display: block;
  }
}

.small-text {
  font-size: rem-calc(14);
}


.hint-text {
  font-size: rem-calc(14px);
  color: $medium-dark-gray;
}

.tiny-text {
  font-size: rem-calc(12px);
}

.lowercase
{
  text-transform: lowercase;
}

/* angular */
[ng-click],
[data-ng-click],
[x-ng-click] {
    cursor: pointer;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield;
}

input[type="text"]:focus,
textarea:focus {
    -webkit-text-size-adjust: 100%;
}

// prevent zoom on focus on touch devices
// media query is not perfect but we're trying to smaller target mobile devices on which it would zoom (on iOS)
@media screen and (-webkit-min-device-pixel-ratio:0) and (max-width: 500px) and (orientation: portrait), screen and (-webkit-min-device-pixel-ratio:0) and (max-width: 768px) and (max-height: 500px) and (orientation: landscape)
{
  textarea,
  input,
  input.no-zoom,
  input.quantityField {
    font-size: 16px !important;
  }
}


.tooltip
{
  pointer-events: none;
  box-shadow: 1px 2px 4px #999;
}