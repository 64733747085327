.help {
  .help-page-questions {
    position: relative;

    .utility-button {
      display: block;
      float: right;
      margin: 0;

      &.admin-link {
        margin-right: 15px;
        padding: rem-calc(9.5px 12px);
      }
    }

    .faq-answer-image {
      display: block;
      max-width: 100%;
      margin: 15px 0;
      border: 1px solid $light-gray;
    }

    .embed-container {
      margin-top: 30px;
    }

  }
}



.help-centre {

  .callout.help-centre-welcome,
  .callout.help-centre-enquiry {
    >a:last-of-type {
      margin-bottom: inherit;
    }

    .help-text.final {
      margin: rem-calc(10px 0 0 0);
    }
  }

  .thickness-field {
    &.ng-hide.ng-hide-animate {
      display: none !important;
    }
  }

  .enquiry-add-more-items-info {
    margin-top: rem-calc(30px);
    color: #000;
  }


  .enquiry-confirmations-table,
  .enquiry-profile-table {
    tbody {
      border: none !important;
    }

    // @NOTE that foundation automatically adds a hover colour because the table has the class .hover
    // but it also has .unstriped. unfortunately despite it being unstriped the row hover colour takes on a darker grey 
    // for alternating rows, so here we override it so all rows are the same on hover
    tr:hover {
      background-color: #ededed;
    }

    th,
    td {
      vertical-align: top;

      input {
        margin-top: rem-calc(10px);
      }

      label {
        line-height: 150%;
      }
    }

  }

  .enquiry-profile-table.unstriped {
    margin-bottom: 0;

    th,
    td {
      text-align: left;
      padding: rem-calc(0 30px 10px 0);
    }

    td {
      padding-right: 0;
    }

    tbody,
    tr,
    tr:hover,
    th,
    td {
      background-color: transparent;
    }
  }



  .enquiry-delivery-address {
    .postcode-search-results {
      margin-bottom: rem-calc(15px);
    }
  }

  .enquiry-create-account-form {

    .row.same-address {
      margin-bottom: 9px;
    }

    .postcode-textfield {
      max-width: 20ex;
    }


    .radio-options label {
      display: inline-block;
      font-size: rem-calc(15px);
      line-height: 1.8;

      &:first-child {
        margin-right: 2ex;
      }

      input[type="radio"] {
        vertical-align: middle;
        display: inline-block;
        margin: 0 2px 0 0;
        position: relative;
        top: -2px;
      }
    }

    .field-container {
      label {
        font-size: rem-calc(15px);
      }

      input[type="radio"],
      input[type="checkbox"] {
        vertical-align: middle;
        display: inline-block;
        margin: 0 2px 0 0;
        position: relative;
        top: -2px;
      }
    }

    .account-type-options {
      margin-bottom: rem-calc(12px);
    }


    .address-module {
      width: 40ex;
    }



    .postcode-search-results {
      margin: rem-calc(0 0 10px 0);
    }


  .css-slide-up {
    @include ng_slide_up(50px);
  }

  @include breakpoint(small only) {
    .css-slide-up {
      @include ng_slide_up(90px);
    }
  }

  .ng-messages-container {
    margin: rem-calc(-9px 0 12px 0);

    span.help-text.danger {
      margin: 0;
    }
  }

    // 
    @include breakpoint(medium up) {



      .ng-messages-container {
        &::before {
          content: " ";
          display: inline-block;
          width: rem-calc(185px);
        }
      }



      .form-container {
        .form-label-text {
          display: inline-block;
          min-width: rem-calc(180px);
        }

        .form-text-input,
        .field-container {
          display: inline-block;
          vertical-align: top;
        }

        .hint-text.delivery-aim {
          margin: 0;

          &.delivery-aim-after {
            margin-top: rem-calc(-13px);
          }
        }

        #delivery_estimate_text-error {
          display: inline;
        }

      }

      #email_warning_container {
        padding-left: 11.5rem;
        display: none;

        hr {
          color: get-color(secondary);
          background-color: get-color(secondary);
          height: 1px;
          border: 0;
        }

        p.danger {
          margin-bottom: 5px;
        }

        button,
        a {
          display: inline-block;
          margin: 0 0 0 8px;
        }

        .forgot-link {
          font-size: rem-calc(13px);
          margin-bottom: 40px;
        }

        #xhr-reset {
          margin: 0 0 15px 0;
        }
      }
    }

    .form-container {
      background-color: white;
      padding: 0;
    }


    label.terms-checkbox-label {
      margin-bottom: 15px;
      height: rem-calc(25px);
    }


  }

  .active-item {
    .product-search-container {
      position: relative;

      .product-search-results {
        background-color: #fff;
        box-shadow: inset 0px -15px 9px -10px rgba(0, 0, 0, 0.1);
        position: absolute;
        z-index: 10;
        top: 76px;
        left: 0;
        max-height: 240px;
        width: 100%;
        overflow: hidden;
        overflow-y: scroll;

        a {
          display: block;
          padding: 0;
          margin: rem-calc(0 0 3px 0);
          text-decoration: none;

          .thumbnail {
            border: 1px solid #999;
            margin: 0;
            width: rem-calc(50px);
            height: rem-calc(50px);
            display: inline-block;
          }

          &.null-result {
            color: get-color(alert);
            font-weight: normal;
            pointer-events: none;
          }

          &.hint {
            font-weight: normal;
            color: #000;
            text-align: center;
          }
        }
      }

      input {
        text-transform: uppercase;

        &::placeholder {
          text-transform: none;
        }

        ;

        &.code-valid {
          color: get-color(success);
          font-weight: bold;
        }
      }

    }

    .drop-box {
      width: 100%;
      font-size: rem-calc(13px);
      padding-top: rem-calc(32px);
    }

    .image-manager {
      .existing-files {
        @include clearfix;
        margin-bottom: rem-calc(30px);
      }
    }

  }

  // end active-item


  .enquiry-items .item {
    margin-bottom: rem-calc(15px);

    .help-text {
      margin-top: rem-calc(2px);
    }

    &:last-of-type {
      margin-bottom: rem-calc(30px);
    }

    .buttons-container {
      width: rem-calc(46px);
      white-space: nowrap;
      text-align: right;
      float: right;
      height: 0;
      overflow: visible;
    }

    button {
      display: inline-block;

      &:last-child {
        margin-left: rem-calc(5px);
      }

      i {
        font-size: rem-calc(24px);
        position: relative;
        top: 2px;
      }


      &:disabled {

        i.fi-page-edit,
        .fi-trash {
          color: $disabled-color;

          &.success {
            color: get-color(success);
          }
        }
      }
    }

    .fi-page-edit {
      color: get-color(primary);
    }
  }


}

#videos {
  .video-container {
    margin-bottom: 35px;
  }

  .video-search {
    width: 185px;
  }

  .video-description {
    margin-top: 15px;
  }

  .navigation-buttons {
    display: flex;
    justify-content: space-between;
  }
}