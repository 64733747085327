@use 'sass:color';

.button {
  border: none;
  font-weight: $global-weight-bold;
  color: $white;
  box-shadow: inset -1px -2px 0px 0px color.scale($button-background, $lightness: -15%);
  &.priority-customer {
    background-color: $support-green;
    box-shadow: inset -1px -2px 0px 0px color.scale($support-green, $lightness: -15%);
    &:hover
    {
      background-color: color.scale($support-green, $lightness: -15%);
    }
  }

  &.success {
    color: $white;
    box-shadow: inset -1px -2px 0px 0px color.scale(get-color(success), $lightness: -15%);
    &:active, &:hover, &:focus {
      color: $white;
    }
  }
  &.disabled, &[disabled] {
    box-shadow: inset -1px -2px 0px 0px color.scale($disabled-color, $lightness: -15%);
  }
  &.primary {
    color: $white;
    &:active, &:hover, &:focus {
      color: $white;
    }
  }
  &.secondary {
    color: $white;
    &:active, &:hover, &:focus {
      color: $white;
    }
    box-shadow: inset -1px -2px 0px 0px color.scale(get-color(secondary), $lightness: -15%);
  }
  &.alert {
    color: $white;
    box-shadow: inset -1px -2px 0px 0px color.scale(get-color(alert), $lightness: -15%);
  }
  &.snow {
    border-color: #fff !important;
    color: #fff !important;
    box-shadow: inset -1px -2px 0px 0px color.scale(#fff, $lightness: -15%);

    &.nano
    {
      padding: 0.5em 0.8em 0.6em 0.8em;
    }

    &:hover {
      border-color: color.scale(#FFF, $lightness: -15%);
    }
  }

  &.hollow {
    box-shadow: none;
  }

  > span[aria-hidden="true"]
  {
    display: inline-block;
    margin-right: rem-calc(5px);
  }

  &:focus
  {
    outline: none !important;
  }
}


// button that looks like a link
.styled-as-anchor
{
  color: $anchor-color;
  font-weight: bold;

  &:hover
  {
    color: $anchor-color-hover;
    text-decoration: underline;
  }

  &.danger
  {
    color: get-color(alert);

    &:hover
    {
      color: get-color(alert)
    }
  }

  &:disabled
  {
    color: $disabled-color;
  }
}
