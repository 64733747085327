.bespoke-image-manager  //add &.pricer or &.cart to specify implementation
{
  .existing-files, .bespoke-user-files
  {
    @include clearfix;

    .file-thumb
    {
      display: block;
      float: left;
      width: 45px;
      height: 45px;
      margin: 0 8px 15px 0;
      position: relative;
      top: 21px;

      .main-link
      {
        width: 100%;
        height: 100%;
        cursor: pointer;

        img
        {
          width: 45px;
          height: 45px;
          display: block;
        }
      }

      .delete-button
      {
        border-radius: 50%;
        color: get-color(alert);
        position: absolute;
        top: -4px;
        right: -4px;
        height: 17px;
        width: 17px;
        overflow: hidden;
        background-color: #fff;
        text-decoration: none;

        &::before{
          position: absolute;
          display: block;
          top: 3px;
          left: 0;
          right: 0;
          text-align: center;
        }

        &:hover
        {
          text-decoration: none;
        }

      }


    }
  }


  .progress-container
  {
    font-size: 11px;
    margin-top: 15px;
  }

  .existing-images{
    @include clearfix;
    margin-bottom: 15px;
  }

  .re-order-hint
  {
    position: absolute;
    right: -12px;
    top: 33px;
    width: 60px;
    text-align: center;
    color: #666;
    text-transform: uppercase;
    font-size: 11px;
  }


  .file-progress
  {
    @include ng_transition_opacity_ng_repeat;
    height: 17px;
    position: relative;
    width: 100%;

    margin-bottom: 2px;
    &:first-child
    {
      margin-top: 10px;
    }

    span
    {
      color: #fff;
      font-size: 11px;
      font-weight: bold;
      left: 4px;
      position: absolute;
      text-overflow: ellipsis;
      top: 1px;
      white-space: nowrap;
      z-index: 10;
    }

    .progress-bar
    {
      width: 100%;
      height: 16px;
      font-size: 11px;
      font-weight: normal;
      line-height: 100%;
      background-color: #ccc;
      color: #fff;

      border-radius: $panel-small-radius;

      > div
      {
        border-radius: $panel-small-radius;
        @include transition_size;
        background-color: #0086d6;
        color: #fff !important;

        height: 15px;
      }
    }

    &.error .progress-bar
    {
      background-color: #f00;
    }

  }


  &.cart
  {
    .bespoke-user-files
    {
      .file-thumb
      {
        margin: 0 8px 15px 0;
        position: relative;
        top: 0;
      }
    }
  }


// file drop area
.drop-box {
  border-radius: 8px;
  background-color: #eee;
  border: 2px dotted #ccc;
  box-sizing: border-box;
  float: left;
  height: 90px;
  left: -1px;
  margin-right: 10px;
  padding-top: 36px;
  position: relative;
  text-align: center;
  width: 120px;

  &.dragover {
    border-color: #0e0;
    background-color: #b2fab2;
  }

  &.dragover-err {
    border-color: #f00;
    background-color: #ffb2b2;
  }
}

}
