@mixin panel_shadow
{
  box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.35);
}

@mixin transition_opacity
{
  transition: opacity 160ms ease-in-out;
}

.transition-opacity
{
  @include transition_opacity;
}


.transition-opacity-300
{
  -webkit-transition: opacity 300ms ease-in-out;
  -moz-transition: opacity 300ms ease-in-out;
  -ms-transition: opacity 300ms ease-in-out;
  -o-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
}


@mixin ng_transition_opacity_ng_repeat
{
  transition: opacity 0.6s ease-out;

  &.ng-enter {
    opacity: 0;
  }
  &.ng-enter-active {
    opacity: 1;
  }
  &.ng-leave {
    opacity:1;
  }
  &.ng-leave.ng-leave-active {
    opacity:0;
  }
}


@mixin ng_fade_show_hide
{
  transition: opacity 0.6s ease-out;

  &.ng-hide {
    opacity: 0;
    display: none;
  }

  &.ng-hide-add
  {
    display: block !important;
  }

  &.ng-hide-remove {
    display: block !important;
  }
}


@mixin ng_slide_up($max_height) {

  transition: .3s ease-in-out all;
  max-height: $max_height;
  overflow: hidden;

  &.ng-hide {
    max-height: 0;
  }
}


@mixin ng_fade_show_hide_timed($time)
{
  transition: opacity $time ease-out;

  &.ng-hide {
    opacity: 0;
    display: none;
  }

  &.ng-hide-add
  {
    display: block !important;
  }

  &.ng-hide-remove {
    display: block !important;
  }
}


@mixin transition_size
{
  -webkit-transition: width 250ms ease-in-out, height 250ms ease-in-out;
  -moz-transition: width 250ms ease-in-out, height 250ms ease-in-out;
  -ms-transition: width 250ms ease-in-out, height 250ms ease-in-out;
  -o-transition: width 250ms ease-in-out, height 250ms ease-in-out;
  transition: width 250ms ease-in-out, height 250ms ease-in-out;
}


@mixin transition_sidebar_cart
{
  transition: max-height 500ms ease-in-out, margin 300ms ease-in-out;
}


@mixin transition_max_height
{
  transition: max-height 500ms ease-in-out;
}

@mixin transition_min_height
{
  transition: min-height 500ms ease-in-out;
}


@mixin transition_height
{
  transition: height 350ms ease-in-out;
}

.transition-abs-height
{
  @include transition_height;
}




@mixin field_invalid
{

  border: 1px solid get-color(alert);
  background-color: rgba(get-color(alert), 0.6) !important; //@TODO this being !important is a pain!
  color: #000;
  &::-webkit-input-placeholder {
    color:    #900;
  }
  &:-moz-placeholder {
    color:    #900;
  }
  &:-ms-input-placeholder {
    color:    #900;
  }
  &::-moz-selection {
    background:#300;
    color: get-color(alert);
  }
  &::selection {
    background:#300;
    color: get-color(alert);
  }
}


@mixin field_warning
{

  border: 1px solid get-color(warning);
  background-color: rgba(get-color(warning), 0.2);
  color: #000;
  &::-webkit-input-placeholder {
    color:    #900;
  }
  &:-moz-placeholder {
    color:    #900;
  }
  &:-ms-input-placeholder {
    color:    #900;
  }
  &::-moz-selection {
    background:#300;
    color: get-color(warning);
  }
  &::selection {
    background:#300;
    color: get-color(warning);
  }
}


@mixin select_invalid
{

  border: 1px solid get-color(alert);
  background-color: rgba(get-color(alert), 0.6);
  color: get-color(alert);
}



@mixin transition_fields
{
  transition: all 100ms ease-in-out;
}


@mixin read_only_panel
{
  border: none;
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  color: _darken($disabled-color, 15%);
  text-decoration: none;
  -webkit-text-fill-color: #222;
  opacity: 1; /* required on iOS */
}
