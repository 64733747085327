.moreinfo
{
  .video-container
  {
    margin-bottom: rem-calc(40px);
  }

  .lead-paragraph {
    margin-top: rem-calc(40px);
  }
}
