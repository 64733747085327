// is .callout
// .order-payment-panel
// {
//   // max-width: 550px; 
//   // padding: 15px 0;
//   // border: 1px;
//   // margin-top: 20px;
// }
.payment-heading
{
  text-transform: none;
  font-weight: normal; 
  font-size: 18px; 
  font-family: Helvetica, arial; 
  /* margin-top: 20px;  */
  margin-bottom: 0; 
  color: #000;

  &.bacs-pending
  {
    margin-bottom: rem-calc(15px);
  }
}
.payment-methods
{
  margin-top: 10px;
}
.payment-methods label
{
  display: block;
  font-size: 14px;
  padding: 5px 15px 6px;
  // background: #eee;
  margin-bottom: 2px;
}
.payment-methods label.active
{
  background-color: _lighten(get-color(success), 10%);
}
.payment-methods label.disabled
{
  color: #666;
}  
.payment-methods label input
{
  margin: 0 10px 0 0;
  position: relative;
  top: 2px;
}
.payment-method-container
{
  display: none;
}
h3.paypal-header {
  margin-bottom: rem-calc(32px);
}