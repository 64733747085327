#sidebar-warning
{
  display: none;
}

@media only screen and (min-width: 1024px) and (max-width: 1100px){
  #sidebar-warning
  {
    background-color: get-color(warning);
    display: block;
    p
    {
      color: #fff;
      font-size: rem-calc(16px);
      line-height: rem-calc(18px);

      a
      {
        text-decoration: underline;
        color: #fff;
        font-weight: normal;
      }
    }
  }
}



#sidebar-stats
{
  min-width: 322px;
  background-color: #fff;

  position: relative;

  .refresh-stats
  {
    position: absolute;
    top: 2.4rem;
    right: 0;
  }

  .stats-link
  {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    font-size: rem-calc(13px);
  }

  .stats-info
  {
    color: $dark-gray;
    font-size: rem-calc(14px);
    margin-bottom: 5px;
    &:last-child
    {
      margin-bottom: 0;
    }
  }


  .stats-info.loading
  {
    display: none;
  }


  &.loading
  {
    .stats-info
    {
      visibility: hidden;

      &.loading
      {
        display: block;
        visibility: visible;
        height: 0;
        margin: 0;
        overflow: visible;
      }
    }
  }

  .stats-value
  {
    font-weight: bold;
  }

  .help-text
  {
    margin-top: rem-calc(-2px);
    font-size: rem-calc(12px);
  }

}

.sidebar-panel
{
  h6
  {
    font-size: rem-calc(14px);
    &.subhead
    {
      font-size: rem-calc(13px);
      color: $dark-gray;
      margin-bottom: 0;
    }
  }

  ul, li
  {
    font-size: rem-calc(14px);
  }

  .pseudo-label
  {
    display: inline-block;
    min-width: rem-calc(140px);
  }
  
  .sidebar-toggle-button::before
  {
    display: block;
    content: "–";
  }

  &.collapsed
  {
    max-height: rem-calc(20px);
    overflow: hidden;
    padding-top: rem-calc(1px);
    .sidebar-toggle-button::before
    {
      display: block;
      content: "+";
    }
  }
}

.sidebar-stock-panel, .sidebar-factory-info, .sidebar-parts-info
{
  min-width: 322px;
  input.text {
    max-width: 12ex;
    text-transform: uppercase;
  }
  textarea
  {
    width: 100%;
  }

  h6
  {
    font-size: rem-calc(14px);
    &.subhead
    {
      font-size: rem-calc(13px);
      color: $dark-gray;
      margin-bottom: 0;
    }
  }

  p
  {
    font-size: rem-calc(14px);
    &.total-length
    {
      margin-top: rem-calc(-3px);
      margin-bottom: 0;
      .val
      {
        font-weight: bold;
      }
    }
  }

  .pricer-info-text p
  {
    margin-bottom: 0;
  }


  .stock-location, .pricer-min-level-container
  {
    label, p
    {
      display: inline-block;
      font-size: rem-calc(14px);
      input
      {
        margin-left: rem-calc(10px);
      }

      &.read-only
      {
        color: $dark-gray;
        margin: rem-calc(-8px 0 6px 0);

        &.danger
        {
          color: get-color(alert);
        }

        &.warning
        {
          color: get-color(warning);
        }
      }
    }

    .text.stocklocation, .text.pricer-run-quantity
    {
      display: inline-block !important;
    }

    .saved-hint
    {
      color: get-color(primary);
      display: none;
      line-height: rem-calc(14px);
      margin-left: rem-calc(10px);
      white-space: nowrap;
    }

  }


  .save-stock-button, .load-history
  {
    margin: 0;
  }


  .load-history
  {
    margin-bottom: 15px;
    &:last-of-type
    {
      margin-bottom: 0;
    }
  }

  table.history
  {
    margin-top: 2em;
  }

  table
  {
    margin-bottom: 20px;
    th, td
    {
      vertical-align: top;
      font-size: rem-calc(13px);
      label, input
      {
        margin: 0;
        height: auto;
        line-height: 1;
        font-size: rem-calc(13px);
      }

      label
      {
        position: relative;
        top: rem-calc(1px);
      }
    }
  }
}




.sidebar-stock-table
{

  input.takenvalue, input.stockvalue
  {
    padding: 0;
    height: auto;
    line-height: 1;
    color: #000;
    &.zero-taken
    {
      color: $black;
    }

    &.over-allocated
    {
      color: get-color(alert);
    }

    &:disabled
    {
      background: transparent;
      border: none;
      box-shadow: none;
      text-decoration: none;
    }
  }

  &.units
  {
    td
    {
      text-align: center;
      input.text
      {
        display: inline-block;
        text-align: center;
      }
    }
  }
}


.sidebar-factory-info
{
  .button-container
  {
    button.button
    {
      margin: 0;
      &:first-child
      {
        margin-right: 1em;
      }
    }
  }

  .pdf-container
  {
    margin-top: rem-calc(15px);

    span
    {
      font-size: rem-calc(12px);
    }
  }
}
