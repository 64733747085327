.account
{

  .orders, .estimates, .invoices, .credit-notes
  {
    td
    {
      font-size: rem-calc(13px);
      .button
      {
        margin: 0;
        &:hover
        {
          text-decoration: none;
        }
        white-space: nowrap;
      }

      p.success
      {
        color: get-color(success);
        font-weight: bold;
        margin-bottom: 0;
      }
      .hint-text
      {
        margin-bottom: 0;
        font-size: rem-calc(12px);
        line-height: 14px;
        &.with-button
        {
          margin-top: rem-calc(5px);
        }
      }
      .request-action-buttons{
        min-width: 113px;
      }
      &.pay-now-buttons{
        width: 81px;
        padding-right: 0;
        min-width: 81px;
      }
      .highlight{
        color: _darken(get-color(warning), 10%);
      }

    }

    tr.cancelled td:not(.order-number-cell), tr.cancelled td a
    {
      color: $light-gray;
      text-decoration: line-through;
    }
    tr.expired td
    {
      color: $medium-dark-gray;
    }
    tr.incomplete td
    {
      color: red;
    }

    .order.not-approved
    {
      .order-number-cell
      {
        a.myaccount-detail-link.has-tip
        {
          color: _darken(get-color(warning), 10%) !important;
          border-bottom: none;
        }
        .fi-info
        {
          border-bottom: none;
          font-size: rem-calc(18px);
          position: relative;
          top: 3px;
          color: _darken(get-color(warning), 10%) !important;
          margin: 0;
          vertical-align: bottom;

        }
      }
    }
    .invoice-link, .credit-note-link
    {
      font-size: rem-calc(13px);
      line-height: rem-calc(16px) !important;
      display: table;
  
      &::before
      {
        content: " ";
        background-repeat: no-repeat;
        background-size: 14px 19px;
        display: inline-block;
        height: 20px;
        margin-right: 5px;
        position: relative;
        top: 3px;
        width: 15px;
      }
      &.invoice::before,&.credit-note::before
      {
        background-image: url("/images/pdf-icon-tiny.png");
      }
    }

  }


  .manage-account {
    .form-container {
      hr {
        border: 0;
        background-color: $body-background;
        height: 5px;
        margin: rem-calc(35px 0 14px);
      }

      input, textarea {
        &:read-only:disabled {
          background-color: transparent;
          border: transparent;
          box-shadow: none;
          padding-left: 0;
          &:focus {
            background-color: transparent;
            border: transparent;
            outline: none;
          }
        }      
      }

    }
  }

  .sidebar-panel.user-account.company-logins, .manage-account .company-logins {
    .contact-type {
      font-size: rem-calc(14px);
    }

    .logins-info {
      dt, dd {
        margin-bottom: 6px;
      }
    }

    .login-account {

      &.inactive {
        color: $disabled-color;
      }

      &.company-admin {
        color: $support-green;
      }

      .help-text {
        display: block;
        color: inherit
      }

      .contact-type
      {
        span
        {
          display: inline-block;
          min-width: rem-calc(100px);
        }
      }

      &:not(:last-child){
        margin-bottom: rem-calc(20px);
      }

      .buttons-container {
        margin-left: rem-calc(100px);
        a {
          display: inline-block;
          &:not(:last-child) {
            margin-right: rem-calc(10px);
          }
        } 
      }

    }


    .action-buttons {
      button.button {
        margin-bottom: 0;
      }

      .cancel-new-login-button {
        display: none;
      }

      &.show-form {
        .new-login-button {
          display: none;
        }
        .cancel-new-login-button {
          display: inline-block;
        }
      }

    }
  }

  .manage-account .new-login-form-container {
    display: none;
  }


  .form-container.duplicate-accounts {

    .account-matching-show-on-list {
      display: none;
    }

    .potential-matches {
      display: none;

      .company-match {
        margin-bottom: rem-calc(25px) !important;
        font-size: rem-calc(14px);
        span
        {
          display: inline-block;
          min-width: rem-calc(100px);
        }
        .actions {
          margin: 15px 0 0 100px;
          button.button {
            display: inline-block;
            margin-bottom: 0;
          }
        }
        
      }
    }
  }




  .data-protection
  {
    p.help-text
    {
      font-size: rem-calc(13px);
    }

    label.help-text
    {
      font-size: rem-calc(13px);
      line-height: 1.6;
      input
      {
        position: relative;
        top: 2px;
        margin: rem-calc(0 5px 0 0);
      }
    }
    
    hr
    {
      border: 0;
      border-top: 1px dotted #ccc;
      height: 1px;
      margin: rem-calc(13px 0 20px 0);
    }

    h3
    {
      font-size: rem-calc(16px);
      margin-bottom: rem-calc(10px);
    }

    h6
    {
      font-size: rem-calc(13px);
      font-weight: bold;
      margin-bottom: rem-calc(7px);
    }

  }

  .delete-account-last-chance
  {
    display: none;
    margin-bottom: 0;

    h6
    {
      color: get-color(alert);
    }

    label
    {
      font-size: rem-calc(13px);
    }

    input.text
    {
      margin-top: rem-calc(8px);
    }

    button
    {
      margin-bottom: 0;
    }
  }


  .trade-info-annotation
  {
    margin-top: rem-calc(20px);
  }
}
