/**
 * Foundation accordion
 * Note that we also use accordion.js, see _accordion-js.scss
 */

 @use 'sass:color';

.accordion-title {
  font-size: 1rem;
  color: $black;
  &:hover, &:focus {
    color: color.scale($black, $lightness: 50%);
  }
}

.help
{

  .accordion-content
  {
    background-color: color.scale($body-background, $lightness: 50%);
  }

  .submit-container
  {
    margin-top: rem-calc(15px);
  }

}
