$form-spacing-reduced: calc($form-spacing / 2);

// is class on body

.contact-form {

  background-color: red;

  #{text-inputs()},
  textarea {
    margin-bottom: $form-spacing-reduced;
  }
  label.middle {
    $input-border-width: get-border-value($input-border, width);
    margin: 0 0 $form-spacing-reduced;
    padding: (calc($form-spacing-reduced / 2) + rem-calc($input-border-width)) 0;
  }

  .add-row-button
  {
    margin-top: $form-spacing;
  }

  .file-upload-row
  {
    label
    {
      box-sizing: border-box;
      display: inline-block;
      width: auto;
      padding-right: 6px;
      min-width: 18ex;
    }
    input.button[type="file"]
    {
      width: auto;
      display: inline-block;
      position: relative;
      top: 4px;
    }
  }

  .sample-returns
  {
    margin: rem-calc(25px 0);
  }

  #product-row-template, #bespoke-row-template
  {
    display: none;
  }

  #product-row-container, #bespoke-row-container
  {
    .row:first-child
    {
      .remove-button-container
      {
        display: none;
      }
    }

    .inline-block
    {
      vertical-align: top;
      &.prod-code
      {
        width: 12ex;
      }
      &.quantity
      {
        width: 12ex;
      }
      &.timber
      {
        width: 24ex;
        margin-right: 3ex;
      }
      &.description
      {
        width: 28ex;
        margin-right: 2ex;
      }
      &.remove-button-container
      {
        width: auto;
      }
    }
  }

  #bespoke-row-container
  {
    .inline-block
    {
      margin-right: 2ex;
    }
  }

}
