p.help-text.uk-delivery
{
  color: #000;
  margin-top: rem-calc(10px);
}



.form-container {
  @include callout($light-gray);
}
.title-textfield,
.productcode-textfield,
.quantity-textfield {
  max-width: 8ex;
  &.month-mm, .card-cvc
  {
    width: 6ex;
  }
  &.year-yyyy
  {
    width: 9ex;
    max-width: 9ex;
  }
}
.name-textfield,
.address-textfield,
.checkout-textarea,
.form-select {
  max-width: 40ex;
  line-height: 140%;
}
.checkout-textarea, .address-textarea
{
  resize: none;
  min-height: rem-calc(74px) !important; // @NOTE !important needed because jquery.autogrow sets height via style attribute
}
.form-select
{
  display: block;
}
.tel-textfield {
  max-width: 18ex;
}
.cardholder-name
{
  max-width: 32ex;
}
.card-cardnum-texfield
{
  max-width: 26ex;
}
.payment-ref-texfield
{
  max-width: 22ex;
}
.mid-textfield {
  width: 30ex;
}
.short-textfield {
  max-width: 16ex;
}
.postcode-textfield {
  max-width: 16ex;
  text-transform: uppercase;

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    text-transform: capitalize;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    text-transform: capitalize;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    text-transform: capitalize;
  }
  &:-moz-placeholder { /* Firefox 18- */
    text-transform: capitalize;
  }
}

label.error {
  color: get-color(alert);
  margin-top: -$form-spacing;
  margin-bottom: calc($form-spacing/2);
}

.match-label {
  @include form-label;
}

select:disabled {
  border: none;
  background: #FFF;
  padding-left: 0;
  padding-right: 0;
  font-size: 1rem;
}

select.ng-invalid
{
  @include select_invalid;
}


dl.floated
{
  @include clearfix;
  dt
  {
    float: left;
    clear: left;
    width: 20ex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: normal;
    margin: 0 0 10px 0;
  }

  dd {
    font-weight: bold;
    margin: 0 0 10px 21ex;
  }
}

.quantity-textfield, .name-textfield, .stockvalue, .quantityField
{
  &.ng-invalid.ng-dirty, &.invalid
  {
    @include field_invalid;
  }

  &.ng-invalid-range.ng-dirty
  {
    @include field_invalid;
  }

  @include transition_fields;

  &.ng-valid:focus, &.ng-pristine:focus
  {
    border: 1px solid #999 !important;
    background-color: #dfe9f7 !important;
    -webkit-box-shadow: 0 0 2px 2px #eee;
    -moz-box-shadow:    0 0 2px 2px #eee;
    box-shadow:         0 0 2px 2px #eee;
  }

  &[disabled], &.ng-invalid[disabled], &.ng-invalid-range.ng-dirty[disabled]
  {
    background-color: rgba(get-color(secondary), 0.6);

    border: 1px solid get-color(secondary);
    color: #666;
    text-decoration: line-through;

    &::-webkit-input-placeholder {
      color: #666;
      text-decoration: line-through;
    }
    &:-moz-placeholder {
      color: #666;
      text-decoration: line-through;
    }
    &:-ms-input-placeholder {
      color: #666;
      text-decoration: line-through;
    }

  }
}

// @NOTE that in some places we use the class quantity-field, and in others quantity-textfield
.quantity-field.invalid
{
  @include field_invalid;
}

.address-preformatted, .current-address
{
  font-size: rem-calc(13px);
  margin-left: rem-calc(10px);
  white-space: pre;
}
