$social-icon-width: 44px;
$social-icon-height: $social-icon-width;

#bot-metadata
{
  display: none;
}

.footer {

  margin-top: 2rem;
  color: $white;

  p.bold
  {
    color: #0083D4;
    margin-bottom: rem-calc(2px);
  }

  .p1, .p2, .p3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .p1 {
    background-color: $primary-shade-1;
    p {
      margin-bottom: 0;
    }
    a {
      &:hover,
      &:focus {
        color: $white;
      }
    }
  }
  .p2 {
    color: get-color(primary);
    background-color: $primary-shade-2;
    p {
      margin-bottom: 0;
    }
  }
  .p3 {
    background-color: $primary-shade-3;
  }

  ul.no-bullet li.copyright
  {
    opacity: 0.6;
  }

  a {
    color: $white;
    font-weight: $global-weight-normal;
    &:hover,
    &:focus {
      color: $white;
    }
  }

  .social-links {
    > li {
      display: inline-block;
      margin-right: 0.5rem;

      > a {
        display: block;
        color: transparent;
        text-indent: 100%;
        overflow: hidden;
        white-space: nowrap;
        width: $social-icon-width;
        height: $social-icon-height;
        opacity: 0.5;
        &:hover, &:focus {
          opacity: 1;
        }
      }

      &.facebook > a {
        background: url('../images/facebook.png') center no-repeat;
      }
      &.twitter > a {
        background: url('../images/twitter.png') center no-repeat;
      }
      &.pinterest > a {
        background: url('../images/pinterest.png') center no-repeat;
      }
      &.linkedin > a {
        background: url('../images/linkedin.png') center no-repeat;
      }
    }
  }

  .payment-methods {
    margin: 0 5px;
  }
}

@include breakpoint(retina) {

  .footer {
    .social-links {
      > li {
        &.facebook > a {
          background-image: url('../images/facebook@2x.png');
          background-size: $social-icon-width $social-icon-height;
        }
        &.twitter > a {
          background-image: url('../images/twitter@2x.png');
          background-size: $social-icon-width $social-icon-height;
        }
        &.pinterest > a {
          background-image: url('../images/pinterest@2x.png');
          background-size: $social-icon-width $social-icon-height;
        }
        &.linkedin > a {
          background-image: url('../images/linkedin@2x.png');
          background-size: $social-icon-width $social-icon-height;
        }
      }
    }
  }
}
