#tooltip-layer
{
	position: absolute;
	z-index: 500;
	top: 0;
	left: 0;

  #tooltip
  {
  	width: 210px;
  	height: auto;
  	background-color: $white;
  	position: absolute;

  	-moz-border-radius: $panel-small-radius;
  	-webkit-border-radius: $panel-small-radius;
  	border-radius: $panel-small-radius;

  	-moz-box-shadow: 0px 1px 3px #ccc;
  	-webkit-box-shadow: 0px 1px 3px #ccc;
  	box-shadow: 0px 1px 3px #ccc;

    &.small
    {
      width: 125px;
    }

    .content
    {
      font-size: 14px;
    	text-align: left;
    	padding: 10px;
    	line-height: 120%;
    }

  }
}
