/**
 * AccordionJS - https://accordion.js.org / https://github.com/awps/Accordion.JS
 * Note that we also use accordion.js, see _accordion-js.scss
 */

.accordionjs {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 10px;
  margin-bottom: 20px;
}
.accordionjs .acc_section {
  border: 1px solid #ccc;
  position: relative;
  // z-index: 10;
  margin-top: -1px;
  overflow: hidden;
}
.accordionjs .acc_section .acc_head {
  position: relative;
  background: #fff;
  padding: 10px;
  display: block;
  cursor: pointer;
}
.accordionjs .acc_section .acc_head h3 {
  line-height: 1;
  margin: 5px 0;
}
.accordionjs .acc_section .acc_content {
  padding: 10px;
}
.accordionjs .acc_section:first-of-type,
.accordionjs .acc_section:first-of-type .acc_head {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.accordionjs .acc_section:last-of-type,
.accordionjs .acc_section:last-of-type .acc_content {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.accordionjs .acc_section.acc_active > .acc_content {
  display: block;
}
.accordionjs .acc_section.acc_active > .acc_head {
  background: #F9F9F9;
  border-bottom: 1px solid #ccc;
}
