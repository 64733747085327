.product-images {

  .thumbnail {
    margin-bottom: 1rem;
  }

  &.replaced {    
    img {
      filter: grayscale(1);
      opacity: 0.6;
    }
    .main-image {
      position: relative;
      display: block;
      &::before {
        content: "NOT AVAILABLE";
        display: block;
        position: absolute;
        left: 0;
        top: rem-calc(30px);
        bottom: auto;
        margin-top: rem-calc(-12px);
        right: 0;
        width: 100%;
        text-align: center;
        background-color: $white;
        color: get-color(alert);
        z-index: 10;
        padding: 4px 0 3px 0;
      }
      &::after {
        content: "SEE REPLACEMENT";
        display: block;
        position: absolute;
        left: 0;
        top: auto;
        bottom: rem-calc(30px);
        margin-top: rem-calc(-12px);
        right: 0;
        width: 100%;
        text-align: center;
        background-color: $white;
        color: get-color(alert);
        z-index: 11;
        padding: 4px 0 3px 0;
      }
    }

  } // end &.replaced

  .product-small-thumbnails {
    .columns {
      @include grid-column-end;
    }
  }
}