.collection-title {
  .product-code {
    color: get-color(primary);
  }
}


.product-listing-filters
{
  .select-label
  {
    font-size: rem-calc(15px);
    position: relative;
  }

  .filter-container {
    display: flex;

    label {
      white-space: nowrap;
      margin-right: rem-calc(15px);
    }
  }

  .foundation-toggler {
    display: flex;
    justify-content: right;

    .toggle-label,
    .switch {
      color: $black;
      margin: 0;
      padding: rem-calc(0 10px 0 0);
      white-space: nowrap;
    }

    .toggle-label {
      font-size: rem-calc(15px);

      position: relative;
      .staff-only-info {
        display: inline-block;
        position: absolute;
        border-radius: 60px;
        color: get-color(secondary);
        font-size: rem-calc(11px);
        font-weight: bold;
        pointer-events: none;
        bottom: -10px;
        left: 0;
        z-index: 10;
        white-space: nowrap;
      }
    }

    .switch {
      position: relative;
      top: rem-calc(3px);
    }

    >.toggle-label {
      color: $black;
      font-size: rem-calc(15px);
      font-weight: normal;
    }
  }

  @include breakpoint(large) {

    .foundation-toggler {
      .toggle-label {
        font-size: rem-calc(16px);
      }
    }
    .select-label {
      font-size: rem-calc(16px);
    }
  }

  @include breakpoint(small only) {
    .foundation-toggler {
      justify-content: left;
      margin: rem-calc(10px 0 15px 0);
    }
  }
  

}



.product-grid-item
{
  @include grid-column-end;
  padding-left: 0;
  padding-right: 0;

  .item-info, .listing-from-price
  {
    font-size: rem-calc(13);
    line-height: rem-calc(14);

    span.description{
      display: block;
      height: rem-calc(50px);
      line-height: rem-calc(17);
      margin-bottom: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }

    .dimensions, .listing-from-price
    {
      min-height: rem-calc(30px);
    }

    a.title
    {
      color: #000;
      display: block;
      font-size: rem-calc(18);
      font-weight: normal;
      margin-bottom: rem-calc(15px);
    }
  }

  .listing-from-price
  {
    white-space: nowrap;
  }


  select.resultProfileGroup
  {
    padding-top: 3px;
    padding-bottom: 3px;
    height: 1.8rem;
    margin-bottom: 0.2rem;
    margin-top: -0.5rem;
  }
  > .product-grid-item-inner {
    margin: 2px;
    padding: 6px;

    a {
      display: block;
      font-size: rem-calc(14);
      &.item-link {
        margin-right: 0;
      }
    }
    @include breakpoint(small only) {
      .thumbnail {
        margin-bottom: 0;
      }
    }
  }
  @include breakpoint(large) {
    > .product-grid-item-inner {
      padding: 12px;
    }
  }

  .thumb-container
  {
    position: relative;
    margin-bottom: 1rem;
    .thumbnail
    {
      width: 100%;
      overflow: hidden;
      background: $white;
      padding-top: 1rem;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .fi-info.dispatch-info {
      position: absolute;
      display: block;
      opacity: 0.7;
      padding: rem-calc(0 7px 0px 1px);
      text-align: right;
      top: 0;
      right: rem-calc(-9px);
      left: auto;
      color: _lighten(get-color(primary), 3%);
      z-index: 1;

      &:hover {
        opacity: 1;
      }
    }

    .fi-star.featured-info {
      position: absolute;
      display: block;
      opacity: 0.7;
      padding: rem-calc(0 7px 0px 1px);
      text-align: right;
      top: 0;
      left: rem-calc(4px);
      color: _lighten($featured-color, 3%);
      z-index: 2;
      &:hover {
        opacity: 1;
      }
    }

    &::after {
      background-color: _lighten(get-color(primary), 3%);
      border-radius: $global-radius $global-radius 0 0;
      bottom: rem-calc(16px);
      color: $white;
      content: attr(data-content);
      display: block;
      font-size: rem-calc(12px);
      height: rem-calc(19px);
      left: 0;
      top: 0;
      line-height: 12px;
      overflow: hidden;
      padding: 3px 0 0 0;
      pointer-events: none;
      position: absolute;
      text-align: center;
      width: 100%;
    }
  }


  &.unpublished:not(.replaced) {
    .thumb-container {      
      opacity: 0.6;
    }
  }


  &.unlisted
  {
    opacity: 0.6;
    .thumb-container::before
    {
      background: get-color(alert);
      border-radius: $global-radius;
      box-sizing: border-box;
      color: $white;
      content: "Not published";
      font-size: rem-calc(13px);
      font-weight: bold;
      height: rem-calc(28px);
      left: rem-calc(10px);
      line-height: rem-calc(13px);
      margin-top: rem-calc(-14px);
      padding: rem-calc(8px 0);
      pointer-events: none;
      position: absolute;
      right: rem-calc(10px);
      text-align: center;
      text-transform: uppercase;
      top: 50%;
      z-index: 200;
      
    }
  }
  &.unlisted
  {   
    .thumb-container::before 
    {
      background: get-color(warning);
      content: "Not listed";
    }
  }

 

  @include breakpoint(medium) {
    .thumb-container {
      .thumbnail {
        padding-top: 0;
        padding-bottom: 1rem;
      }
      .dispatch-info {
        top: rem-calc(1px);
      }
      &::after {
        top: auto;
        bottom: 0;
        border-radius: 0 0 $global-radius $global-radius;
      }
    }
  }

  &.in-stock {
    > .product-grid-item-inner {
      .thumbnail {
        &:hover,
        &:focus {
          box-shadow: 0 0 4px 1px rgba(get-color(success), 0.8);
        }
      }
      .thumb-container
      {
        .dispatch-info
        {
          color: get-color(success);
        }
        &::after {
          background-color: _darken(get-color(success), 3%);
          content: "In stock";
        }
      }
    }
  }

  &.special-offer
  {
    .listing-from-price .price
    {
      font-weight: bold;
    }

    > .product-grid-item-inner {
      .thumbnail {
        box-shadow: 0 0 0 1px $specials-color-dark;
        &:hover,
        &:focus {
          box-shadow: 0 0 4px 1px $specials-color-dark;
        }
      }
      .thumb-container
      {
        .dispatch-info
        {
          color: $specials-color-dark;
        }
        &::after {
          background-color: $specials-color-dark;
          content: "Special offer";
        }
      }
    }
  }

  &.unpublished {
    > .product-grid-item-inner {
      .thumbnail {
        box-shadow: none;
        &:hover,
        &:focus {
          box-shadow: none;
        }
      }
      .thumb-container
      {
        .dispatch-info
        {
          // hide the infotip
          display: none;
        }
        &::after {
          background-color: _darken(get-color(secondary), 3%);
          content: "Unavailable";
        }
      }
    }
  }

  &.replaced {
    > .product-grid-item-inner {
      .thumb-container::after {
        background-color: #666;
        content: "See replacement";
      }
    }
  }
  &.unpublished {
    .dimensions, .listing-from-price, .stockContainer {
      display: none;
    }
  }

  .button.small
  {
    font-size: rem-calc(11px);
  }

  .fi-info, .fi-star
  {
    border-bottom: none;
    color: get-color(primary);
    display: inline-block;
    font-size: rem-calc(18px);
    left: 1px;
    margin: 0 8px 0 2px;
    position: relative;
    text-decoration: none;
    top: 2px;
    vertical-align: bottom;
  }

  .product-data-wrapper {
    position: relative;
  }

  .stockContainer, .bulk-savings-container
  {
    border-top: 3px solid rgba(get-color(primary), 0.4);
    min-height: rem-calc(95px);
    padding-top: 0.7em;
    padding-bottom: rem-calc(20px);

    h6
    {
      font-size: rem-calc(14px);
      margin: rem-calc(7px 0 2px 0);
      &.trade {
        color: $support-green
      }
    }

    .first-heading h6
    {
      margin-top: 0;
    }

    .cell
    {
      font-size: rem-calc(12px);
      text-overflow: ellipsis;
      overflow: hidden;
      a {
        font-size: rem-calc(12px);
      }
    }


    .grid-x.secondary {
      color: _darken(get-color(secondary), 10%); // because it's on a grey background

      .fi-info {
        display: inline-block;
        margin-left: rem-calc(5px);
        position: relative;
        top: rem-calc(1px);
        &:hover {
          text-decoration: none;
        }
        &.secondary
        {
          color: get-color(secondary);
        }
      }
    }

  }

  .bulk-savings-container {
    display: none;
  }


  .product-data-wrapper.show-bulk-savings {
    .stockContainer {
      display: none;
    }

    .bulk-savings-container {
      display: block;

      .hint-text {
        font-size: rem-calc(12px);
      }

      .accordion, .accordion-content, .accordion-title {
        border: none;
        background-color: transparent;
        padding: 0;
        margin: 0;
      }

      .accordion-title {
        font-size: rem-calc(12px);
        @include link_standard;
        line-height: rem-calc(16.5px);

        &::before {
          right: 0;
        }
      }

      .accordion-content {
        padding-bottom: 15px;
      }

      .accordion-item.is-active .accordion-title::before {
        display: none;
      }

      .accordion-item:last-child .accordion-content {
        padding-bottom: 0;
      }

      // single material - hack the accordion element so it's not clickable
      .accordion.single-material {
        .accordion-item .accordion-title {
          pointer-events: none;
          color: #000;
        }
      }
    }
  }

} // end .product-grid-item


// styles specific to types of product grid (e.g. specials)
.product-grid
{
  &.special-offers
  {
    // is .product-grid-item
    .not-in-offer
    {
      display: none;
    }

    &.staff-mode
    {
      // is .product-grid-item
      .not-in-offer
      {
        display: inline-block;
        opacity: 0.6;
        .thumb-container::after {
          background-color: $specials-color-dark;
          content: "Stock depleted";
        }
      }
    }
  }
}


// IE 9 hack
// for some reason foundation sets the last item to float: right
.lt-ie10
{
  .product-grid-item:last-child
  {
    float: left !important;
  }
}


.back-to-top-link
{
  transition: bottom 200ms ease-out;
  position: fixed;
  z-index: 50;

  bottom: -70px;
  right: 20px;
  margin-top: 0;

  &.show-back-button
  {
    bottom: 20px;
  }

}

@include breakpoint(small only) {
  .back-to-top-link
  {
    display: none;
  }
}
