#popup_container {
	font-family: Arial, sans-serif;
	font-size: 12px;
	min-width: 300px; /* Dialog will be no smaller than this */
	max-width: 600px; /* Dialog will wrap after this width */
	background: #FFF;
	border: solid 5px #999;
	color: #000;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
}

#popup_title {
	font-size: 14px;
	font-weight: bold;
	text-align: center;
	line-height: 1.75em;
	color: #666;
	cursor: default;
	padding: 0em;
	margin: 0em;
}


#popup_content {
	background: 16px 16px no-repeat url('/images/info.png');
	padding: 1em 1.75em;
	margin: 0em;
}

#popup_content.alert {
	background-image: url('/images/info.png');
}

#popup_content.confirm {
	background-image: url('/images/important.png');
}

#popup_content.prompt {
	background-image: url('/images/help.png');
}

#popup_message {
	padding-left: 48px;
}

#popup_panel {
	text-align: center;
	margin: 1em 0em 0em 1em;
}

#popup_prompt {
	margin: .5em 0em;
}
