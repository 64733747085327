
.stock-msg {
  font-size: rem-calc(14);
}

input.quantityField {
  max-width: 3.75rem;
  &.highlighted {
    color: $input-color;
    font-weight: $global-weight-normal;
    background-color: _transparentize(get-color(success), 0.9);
    border-color: get-color(success);
  }
}

.exceeded-hint
{
  color: get-color(primary);
  display: none;
}

.stock-exceeded {
  input.quantityField
  {
    color: get-color(primary);
  }
  .exceeded-hint
  {
    display: block;
  }
}

.quantity-field{
  display: inline-block;
}

.active-marker {
  position: relative;
  padding-left: 5px;
  margin-left: 8px;
  &:after {
  	right: 100%;
  	top: 50%;
  	border: solid transparent;
  	content: " ";
  	height: 0;
  	width: 0;
  	position: absolute;
  	pointer-events: none;
  	border-color: transparent;
  	border-right-color: get-color(success);
  	border-width: 5px;
  	margin-top: -5px;
  }
}

.stock-level-headline {
  font-size: rem-calc(18);
  font-weight: $global-weight-bold;
  margin-bottom: 0.25rem;

  // balance height of larger icon, by using negative bottom margin
  .out-of-stock-icon
  {
    margin-bottom: -8px;
    position: relative;
    top: -3px;
  }

  &.lengths-mode
  {
    margin-top: rem-calc(10px);
  }
}

.pricer-beta-length-mode-label {
  &.secondary {
    color: get-color(secondary);
  }
}
.pricer-beta-length-mode-button {
  margin: 0;
  position: relative;
  top: rem-calc(-4px);
  &.secondary {
    ::after {
      content: "x";
      display: inline-block;
    }
    &:active, &:focus {
      color: get-color(secondary) !important;
      border-color: get-color(secondary) !important;
    }
  }
}

.help-text.product-pricer-special-run-info
{
  margin: rem-calc(6px 0 15px 0);
  max-width: 558px;
}

.pricer-length-stepper-controls {
  float: right;
  white-space: nowrap;

  input, button {
    display: inline-block;
    position: relative;
  }
  input {
    margin-bottom: 0;
    margin-right: 4px;
  }
  button {
    $height: ($input-font-size + ($form-spacing * 1.5) - rem-calc(1));
    width: $height;
    height: $height;
    padding: 0;
    margin-right: 2px;
    margin-top: -1px;
    margin-bottom: 0;

    &:last-child{
      margin-right: 0;
    }
  }
}

.lengths-pricer-link-temp
{
  font-size: rem-calc(11px);
  text-align: right;
  display: block;
  color: get-color(primary);
  margin: 0 5px 10px 0;

  a
  {
    display: inline;
    &.cancel
    {
      color: get-color(alert);
    }
  }
}



#product-price-panel
{

  [ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    // override ng-cloak, it is used here on form elements before they are initialised
    // and we don't want the layout jumping around

    &.cloak-vis
    {
      display: unset;
      visibility: hidden;
    }
  }

  .highlight
  {
    color: get-color(primary);
  }

  .fi-info {
    font-size: rem-calc(18px);
    line-height: rem-calc(18px) !important;
    display: inline-block;
    position: relative;
  }

  .metres-units
  {
    display: inline-block;
  }
  .spare-hint, .minimum-warning
  {
    margin-top: rem-calc(20px);
    text-align: center;
  }
  .minimum-warning
  {
    font-weight: bold;
  }

  // mirror foundation's "is-invalid-input" for angular ng-invalid
  input.ng-invalid.ng-touched
  {
    @include form-input-error;
  }


  button[disabled=disabled], button.disabled
  {
    pointer-events: auto;
  }

  .admin-mode-switcher
  {
    margin: -1rem -1rem 1rem -1rem;

    a
    {
      display: inline-block;
      text-align: center;
      font-size: rem-calc(12px);
      text-transform: uppercase;
      color: #fff;
      background-color: get-color(secondary);

      &.selected, &:hover
      {
        background-color: get-color(primary);
        text-decoration: none;
      }

      &.selected, &.disabled
      {
        cursor: auto;
        pointer-events: none;
      }

      &.disabled
      {
        background-color: get-color(secondary);
        color: $light-gray;
      }

    }
  }


  .subtotal-container
  {
    &.subdued
    {
      opacity: 0.25;
    }
  }

  .product-pricer-subtotal
  {
    label, .subtotal-container
    {
      font-weight: bold;
    }
  }

  .trade-savings
  {
    label, .subtotal-container
    {
      color: _darken(get-color(success), 8%);
      font-weight: normal
    }
  }

  .trade-savings-total
  {
    color: _darken(get-color(success), 8%);
  }

  .pricer-error
  {
    color: get-color(alert);
    text-align: center;
    font-size: rem-calc(14px);
  }

  .vat-info
  {
    margin-top: -0.5rem;

    small
    {
      display: inline;
    }
  }

  .admin-stock-hint
  {
    margin-top: rem-calc(-19px);
  }



  // see also _misc-mixins.scss
  .transition-opacity-300
  {
    opacity: 0;
    &.visible
    {
      opacity: 1;
    }
  }

  .admin-breakdown
  {
    position: relative;
    clear: both;

    .toggle-breakdown
    {
      position: static;
      display: inline-block;
      margin-left: 10px;
    }

    .bd-content
    {

      // hidden by default
      color: _darken(get-color(secondary), 20%);
      font-size: 13px;

      > div
      {
        margin-bottom: 5px;

        &.category
        {
          font-weight: bold;
          span
          {
            min-width: 220px;
          }
        }

        &.nested
        {
          opacity: 0;
          padding-left: 10px;
          margin: 0;
          max-height: 0;
          overflow: hidden;

          span
          {
            min-width: 210px;
          }
        }

        &.highlight
        {
          color: get-color(primary);
        }

        &.total
        {
          color: #000;
          padding-bottom: 6px;
          border-bottom: 1px dashed get-color(secondary);
        }

        span
        {
          display: inline-block;
        }
      }


      // .bd-content
      &.expanded
      {
        > div.nested
        {
          max-height: rem-calc(20px);
          margin: 5px;
          opacity: 0.85;
        }
      }


    }

  } // end OLD .pricer-breakdown

  // old jQuery pricer breakdown - @TODO - remove once that's been take off the site
  .pricer-breakdown
  {
    display: none;
    position: relative;
    clear: both;

    .toggle-breakdown
    {
      position: static;
      display: inline-block;
      margin-left: 10px;
    }

    .bd-content
    {
      // hidden by default
      color: _darken(get-color(secondary), 20%);
      font-size: 13px;

      > div
      {
        margin-bottom: 5px;


        &.category
        {
          font-weight: bold;
          span
          {
            min-width: 220px;
          }
        }

        &.nested
        {
          opacity: 0.85;
          display: none;
          padding-left: 10px;

          &.visible, &.always-show
          {
            display: block;
          }

          span
          {
            min-width: 210px;
          }
        }

        &.highlight
        {
          color: get-color(primary);
        }

        &.total
        {
          color: #000;
          padding-bottom: 6px;
          border-bottom: 1px dashed get-color(secondary);
        }

        span
        {
          display: inline-block;
        }
      }

    }

  } // end OLD .pricer-breakdown
}


.pricer { 

  .status {
    > div {
      position: relative;
      top: 0;
    }
  }
  .status, .actions {
    .add-item-button, .remove-item-button {
      position: relative;
      top: rem-calc(5px);
    }
  }



  

  .machined-icon-blue {
    @include icon-base("/images/sprite-cutter-256.png", 28px, 28px, middle);
    background-size: cover;
  }


  .cad-icon-blue {
    @include icon-base("/images/sprite-cad-256.png?r=2", 28px, 28px, middle);
    background-size: cover;
  }
  
  // is h4
  .moulding-type-heading {
    margin-bottom: 0;
    position: relative;
    // top: rem-calc(5px);
    span {
      position: relative;
      top: rem-calc(-2px);
    }

    .machined-icon-blue {
      @include icon-base("/images/sprite-cutter-256.png", 18px, 18px, middle);
    }
  
    .cad-icon-blue {
      @include icon-base("/images/sprite-cad-256.png?r=2", 18px, 18px, middle);
    }
  }



  .blank-start-buttons-container {
    padding: rem-calc(60px);
    text-align: center;

    .add-moulding-button:first-child {
      margin-right: rem-calc(100px);
    }

    @include breakpoint(small only) {
      padding: rem-calc(20px);

      .add-moulding-button,
      .add-moulding-button:first-child {
        margin: rem-calc(30px) auto;
        display: block;
        max-width: rem-calc(210px);
      }
    }

    .machined-icon-blue {
      margin: rem-calc(-6px 10px -4px -5px);
    }

    .cad-icon-blue {
      margin: rem-calc(-6px 10px -4px -5px);
    }
  }


  .add-more-buttons-container {
    .machined-icon-blue {
      margin: rem-calc(-8px 8px -6px -7px);
    }

    .cad-icon-blue {
      margin: rem-calc(-8px 8px -6px -7px);
    }
  }


  .bespoke-pricer .pricer-table 
  {

    tbody {
      border: none;
    }
    .item-type-row {
      td {
        padding-top: rem-calc(15px);
        padding-bottom: 0;
      }
    }
  }
  

}