#popup_container.wrpConfDialogue
{

  line-height: 1.6em;
  font-size: 13px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  overflow: hidden;
  @include panel_shadow;
}


#popup_container.wrpConfDialogue #popup_title
{
	font-size: 15px;
	font-weight: normal;
	text-align: center;
	line-height: 1.75em;
	color: #fff;
	background-image: none;
	background-color: get-color(primary);
	border: none !important;
	border-bottom: none !important;
	cursor: default;
	padding: 0em;
	margin: 0em;
}
