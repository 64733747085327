$bot-typing-bg: _transparentize(get-color(primary), 0.8);
$wrp-typing-bg: _transparentize($support-green, 0.8);

.contact-system {
  .typing-indicator {
    background-color: $bot-typing-bg;

    &.ticket-mode {
      background-color: $wrp-typing-bg;
    }

    will-change: transform;
    // width: auto;
    border-radius: 5px;
    padding: 12px;
    display: inline-block;
    margin: rem-calc(0 15px 15px 15px);

    &.with-menu {
      margin-top: 15px;
    }
    position: relative;
    display: flex;
    width: fit-content;

    > span {
      height: 7px;
      width: 7px;
      margin-right: rem-calc(4px);
      background-color: #fff;
      display: block;
      border-radius: 50%;
      opacity: 0.3;

      &:last-child {
        margin-right: 0;
      }
    }

    &.show {
      animation: 2s bulge infinite ease-out;

      > span {
        @for $i from 1 through 3 {
          &:nth-of-type(#{$i}) {
            animation: 1s blink infinite ($i * .3333s);
          }
        }
      }
    }

  }


  @keyframes blink {
    50% {
      opacity: 1;
    }
  }

  @keyframes bulge {
    50% {
      transform: scale(1.1);
    }
  }


}