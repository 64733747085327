.cad-files
{
  .download-container
  {
    text-align: center;
  }

  .callout.files-list
  {
    padding: 0;
  }

  .cad-files-list
  {
    tbody
    {
      border: none;
    }

    label
    {
      font-weight: bold;
    }
 
    td
    {
      vertical-align: middle;
      padding: rem-calc(16px 0 16px 16px);

      &:last-child
      {
        padding-right: rem-calc(16px);
      }

      text-align: left;

      &.thumb
      {
        width: 50px;
      }
    }

    .cad-download-thumb
    {
      background-image: url('/images/dwg-icon.png');
      background-position: top left;
      background-repeat: no-repeat;
      background-size: contain;
      box-sizing: border-box;
      display: block;
      height: 60px;
      overflow: hidden;
      padding: 0;
      text-indent: 100%;
      vertical-align: bottom;
      white-space: nowrap;
      width: 48px;
      margin: 0;
    }

    .button.small
    {
      margin: 0;
    }

    .help-text
    {
      margin-top: rem-calc(-9px);
    }


  }

}
