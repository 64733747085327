.bespoke-pricer
{
  .pricer-header
  {

    font-size: 14px;

    margin-bottom: 15px;
    position: relative;

    p
    {
      margin: 0 0 9px 0;
    }

    input[type=radio]
    {
      font-size: 14px;
      display: inline;
      margin-right: 1px;
    }

    label
    {
      font-size: 14px;
      display: inline;

      &.delivery, &.collection
      {
        white-space: nowrap;
        display: inline-block;
        margin-right: rem-calc(12px);

        &.highlight
        {
          color: get-color(primary);
        }
      }

      &.bold
      {
        font-weight: bold;
      }
    }

  }


  .override-container
  {
    display: inline-block;
    padding-bottom: 3px;

    // because input field appears when the custom price is active
    // we add this class which cancels out the change in height padding
    &.custom-active
    {
      padding-bottom: 0px;
    }

    .labour-rate
    {
      display: inline;
      margin-top: -8px;

      &.low-warning
      {
        color: get-color(primary);
      }

    }

    &.highlight
    {
      color: get-color(primary);
    }

    .override-label
    {
      margin: 0;
    }

  }

  .notes-row
  {
    label span
    {
      font-size: rem-calc(12px);
      &.label-title
      {
        font-weight: bold;
      }
        
    }
  }


  .fi-info
  {
    font-size: rem-calc(18px);
    position: relative;
    top: 2px;
    left: 1px;
    color: get-color(primary);
    margin: 0 8px 0 2px;
    vertical-align: bottom;
  }


  .exit-buttons
  {
    float: right;
    position: relative;
    top: -6px;

    .button
    {
      margin-bottom: 0;
    }

    .trade-label
    {
      margin-right: rem-calc(15px);
    }
  } 


  .product-mode-heading {
    margin-top: 15px;
  }

  .product-mode-cancel-button
  {
    position: absolute;
    margin: 0;
    top: 20px;
    right: 13px;
  }


  .item-row {
    .close-button {
      position: relative;
      display: block;
      right: auto;
      top: auto;
      span
      {
        position: relative;
        top: -7px;
      }
    }


    &.item-additional.cnc-item td {
      padding-top: 18px;
    }
  }


  .pricer-table
  {

    .item-tasks-container {
      white-space: nowrap;
    }

    .item-tasks {
      font-size: 0;  
      select,
      div {
        min-width: rem-calc(150px);
        max-width: rem-calc(175px);
        display: inline-block;
        float: none;
        vertical-align: top;
      }  
      div {
        margin-left: rem-calc(15px);
  
        select {
          margin: 0;
          display: block;
        }

        input[type=text] {
          margin-top: rem-calc(10px);
        }
      }  
    }
  }


  
  .second-part-label, .par-run-label
  {
    > span
    {
      opacity: 0.6;
      position: relative;
      top: rem-calc(3px);
    }

    .fi-arrow-right
    {
      font-size: 16px;
      margin-left: 2px;
      position: relative;
      top: 2px;
    }
  }

  tr.item-par-row, tr:nth-child(even).item-par-row,
  tr.item-packaging, tr:nth-child(even).item-packaging {
    background-color: $trade_stripe_dark;
    th, td {
      &:nth-child(1), &:last-child{
        position: relative;
        &::before {
          content: " ";
          display: block;
          pointer-events: none;
          position: absolute;
          top: 0;
          left: 0;
          width: rem-calc(4px);
          height: 100%;
          background-color: #fff;
        }
      }
      &:last-child{
        &::before {
          right: 0;
          left: auto;
        }
      }
    }
  }

  .status, .actions
  {
    font-size: 1.8rem;
    > *
    {
      position: relative;
      top: -5px;
    }

    .status-ok
    {
      color: get-color(success);
    }
    .status-warn-minor
    {
      color: get-color(secondary);
    }
    .status-warn
    {
      color: get-color(alert);
    }
    .remove
    {
      color: get-color(alert);
    }
  }


  td.divider-row
  {

    padding-bottom: 15px;

    hr
    {

      background-color: #d9d3ca;
      border: none;
      height: 2px;
    }
  }

  .name-textfield
  {
    width: rem-calc(155px);
    float: none !important;
  }


  .single-digit-select
  {
    width: rem-calc(36px);

    &.with-sign
    {
      width: rem-calc(65px);
    }
  }

  select:disabled {
    border: 1px solid $light-gray;
    color: $light-gray;
    padding-left: rem-calc(7px);
    padding-right: rem-calc(7px);
    font-size: 0.875rem;
  }




  .results
  {

    text-align: left;
    margin: 5px 0;

    &.totals
    {
      margin-top: -8px;
    }

    sup
    {
      vertical-align: top;
      font-size: 85%;
      position: relative;
      top: 4px;
    }


    h2
    {
      text-transform: uppercase;
      font-size: 13px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .result-category
    {
      display: block;
      font-size: 13px;
      line-height: 150%;

      span
      {
        display: inline-block;
        margin-bottom: 2px;
        &.result-label
        {
          margin-top: 2px;
          font-weight: bold;
          min-width: 340px;

          &.disabled
          {
            color: get-color(secondary);
          }
        }
        &.value
        {
          min-width: 220px;
          &.disabled
          {
            color: get-color(secondary);
          }
          &.bold
          {
            font-weight: bold;
          }

          strong.item-net-total, span.item-net-total
          {
            display: inline-block;
            min-width: 70px;
          }

          &.subtotal
          {
            // temporary - reduce opacity of the legacy price-per-meter
            i.legacy-ppm{
              opacity: 0.5;
            }
          }
        }
      }

      span, b
      {
        &.highlight
        {
          color: get-color(primary);

          &.green
          {
            color: $admin-success-green;
          }

          &.red
          {
            color: get-color(alert);
          }
        }

        &.subdued
        {
          color: get-color(secondary);
        }
      }

      .details
      {
        margin-left: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #d9d3ca;

        span.result-label
        {
          font-weight: normal;

          &.bold
          {
            font-weight: bold;
          }
        }
      }

      .details-sub-group {
        padding-bottom: rem-calc(5px);
        margin-bottom: rem-calc(5px);
        border-bottom: 1px dotted #d9d3ca;
      }

      .add-to-cart-button, .remove-from-cart-button
      {
        position: relative;
        top: -4px;
        margin: 0 10px 0 0;

      }

    }


    .cart-buttons
    {
      display: block;
      margin-top: 15px;
    }

  }

  select:focus, input[type="checkbox"]:focus
  {
    border: 1px solid #999;
    background-color: #c8ddf8;
    -webkit-box-shadow: 0 0 2px 2px #ddd;
    -moz-box-shadow:    0 0 2px 2px #ddd;
    box-shadow:         0 0 2px 2px #ddd;
  }



  // just inside .bespoke-pricer here
  .pricer-table
  {
    th, td
    {
      font-size: rem-calc(12px);
    }

    th
    {
      padding: rem-calc(15px 10px 10px 0);
      overflow: hidden;
      text-align: left;
      white-space: nowrap;
      line-height: 1.3;
      vertical-align: bottom;

      &.has-tip
      {
        display: table-cell;
        border-bottom: none;
        color: get-color(primary);
      }

      &.active, &.thickness-mdf-mode
      {
        color: $admin-success-green;
      }

      &.disabled-setting
      {
        color: get-color(secondary);
      }



      &.label-rotated
      {
        padding-right: 0;
        margin-right: -4px;
        height: 50px;
        & > div
        {
          width: 60px;
          display: inline-block;
          white-space: nowrap;
          overflow: visible;
          margin-right: -20px;

          transform: translate(5px,100%) rotate(-60deg);
          transform-origin: -5px 0;

          &:before {
            content: "";
            float: left;
            margin-top: 100%;
          }
        }

      }
    }

    td
    {
      @include clearfix;
      padding: rem-calc(0 15px 10px 0);
      vertical-align: top;

      &:last-of-type
      {
        padding-right: 0;
      }

      input, select
      {
        float: left;
        margin-right: rem-calc(15px);

        &:last-of-type
        {
          margin-right: 0;
        }
      }

      &.counter
      {
        font-size: rem-calc(14px);
        vertical-align: top;
        padding: 7px 8px 0 8px;
        text-align: right;
      }

      &.inline-label
      {
        text-align: right;
        padding: 0px 15px 9px 0;
      }

      &.checkbox-cell
      {
        padding-right: 15px;

        text-align: center; /* center checkbox horizontally */
        // vertical-align: middle; /* center checkbox vertically */


          input[type="checkbox"]
          {
            float: none;
            position: static;
            display: inline-block;
          }


      }

      &.status
      {
        padding-right: 12px;
        .icon16
        {
          position: relative;
          top: 1px;
        }
      }

      &.status, &.actions
      {
        > *
        {
          position: relative;
          left: -8px;
          font-size: 1.4rem;
        }

      }

      &.errors
      {
        span
        {
          display: block;
          margin-bottom: 5px;

        }
        color: get-color(alert);
      }


      .override-container
      {
        float: none;
        margin-top: -10px;
        margin-right: 15px;
        &:last-of-type
        {
          margin-right: 0;
        }

        input.quantity-textfield, input.name-textfield
        {
          margin-top: -8px;
          float: none;
          display: inline-block;
        }

        input[type="checkbox"]
        {
          margin: rem-calc(0 4px 0 0);
          vertical-align: middle;
        }

        label
        {
          font-size: 13px !important;
          line-height: 1;
          display: inline-block;
          margin-right: rem-calc(5px);
          // color: get-color(secondary);
        }

        &.active label
        {
          color: $admin-success-green;
        }

        &.alert label
        {
          color: get-color(alert);
        }
      }

    }

  td.divider-row
  {

    padding-bottom: 15px;

    hr
    {

      background-color: #d9d3ca;
      border: none;
      height: 2px;
    }

  }

  // @NOTE file uploads drop-box is now in _components

}  //end .pricer-table



}
