dt {
  margin-top: 1rem;
  &:first-child {
    margin-top: 0;
  }
}

h2.tagline
{
  color: get-color(primary);
  font-weight: bold;
  font-size: rem-calc(16px);
  line-height: rem-calc(16px);
}


h1.tagline
{
  color: get-color(primary);
}


h1.h1-hero, .h1-hero
{
  font-size: 48px;
  line-height: 48px;
  font-weight: normal;

  a, a:hover
  {
    text-decoration: none;
  }

}
