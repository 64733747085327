.testimonials
{
  blockquote
  {
    display: block;
    background: #fff;
    border-left: 10px solid $light-gray;
    margin: 0 0 rem-calc(15pc) 0;
    padding: 0.5em 10px;
    quotes: "\201C""\201D""\2018""\2019";
    position: relative;

    &:before {
      position: relative;
      top: 3px;
      left: -2px;
      color: $light-gray;
      content: open-quote;
      font-size: 4em;
      line-height: 0.1em;
      margin-right: 0.25em;
      vertical-align: -0.4em;
    }

    &:after {
      color: $light-gray;
      content: close-quote;
      visibility: hidden;
      line-height: 0;
      display: block;
      height: 0;
      width: 0;
      position: absolute;
      top: 0;

    }

    cite
    {
      font-size: rem-calc(15px);
      font-style: italic;
      margin: 0 0 5px 0;
    }

    p {
      display: block;
      &:last-of-type
      {
        margin-bottom: 5px;
      }
    }
  }

}
